import React, { useState, Link } from 'react';
import PropTypes from 'prop-types';
import { SERVER_API_URL } from '../config/Constants'
import strings from "../config/strings";
import Image from "../components/Image"
import ImageBackground from "../components/ImageBackground"
import Utils from "../utils/utils";
import CheckBox from "../components/CheckBox"
import Button from "../components/Button"
import { SECONDARY_COLOR } from "../config/styles";
import detectBrowserLanguage from 'detect-browser-language'
import './Login.css';
import AccountUtils from '../utils/accountUtils';


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';


export async function loginUser(credentials) {

 return fetch(`${SERVER_API_URL}/users/login.json`, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(credentials)
 })
   .then(data => data.json())
}




async function registerUser(credentials) {
 return fetch(`${SERVER_API_URL}/users.json`, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(credentials)
 })
   .then(data => data.json())
}

const getLanguage = () => {
  var lang = detectBrowserLanguage

  if (lang && lang.length >= 2) {
    return lang.substring(0, 2)
  }
  return ''
}



export async function registerAnonymousUser(user) {
  const loginEvent = new CustomEvent('login');
  console.log("REGISTER NEW USER", user)

  const username = user.name ? user.name : AccountUtils.generateUsername();
  const emailPrefix = 'anonymous' + Date.now() + Utils.getUID();
  const email = (user.email && user.email.length > 0) ? user.email : `${emailPrefix}@sumochess.org`;
  //const email = (user.email && user.email.length > 0) ? user.email : `bllsss@sumochess.com`;
  const password = user.password ? user.password : Utils.getUID();
  try {
    var data = await registerUser({
      name: username,
      email,
      password,
      account_created_from: 'web',
      is_anonymous: user.email ? 0 : 1,
      language: getLanguage()
    });

    data = await loginUser({
      email,
      password,
    });

    if (!data || !data.auth_token) {
      alert(strings.wrong_pwd_email);
      return null;
    }


    const loginEvent = new CustomEvent('login', { detail: data });
    window.dispatchEvent(loginEvent);
    return data
  }
  catch (error) {
    alert(error.message)
  }

  return null




}





export default function Login({ setToken, setUser }) {


  const [email, setEmail] = useState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [isRegistering, setIsRegistering] = useState(true);
  const [showConfirmRegister, setShowConfirmRegister] = useState(0)
  const [acceptEmail, setAcceptEmail] = useState();
  const [loading, setLoading] = useState();
  const [checkEmail, setCheckEmail] = useState();



  const handleSubmit = async e => {




    if (isRegistering) {

      if (!validateRegister())
        return


      if (showConfirmRegister == 0) {
        setShowConfirmRegister(1)
        return
      }

      setLoading(true)


      try {
        const data = await registerUser({
          name: username,
          email,
          password,
          account_created_from: 'web',
          language: getLanguage(),
          pp_accepted: 1,
          emails_accepted: acceptEmail ? 1 : 2
        });

        if (data != null && data.code == 550) {
          alert(strings.email_already_registered);
          setLoading(false)
          setShowConfirmRegister(0)
          return;
        }
        if (data != null && data.code == 551) {
          alert(strings.username_already_registered);
          setLoading(false)
          setShowConfirmRegister(0)
          return;
        }
       // alert(JSON.stringify(data))
        setCheckEmail(true)

      }
      catch (error) {
        alert(error.message)
      }

      setLoading(false)

    }
    else {

      setLoading(true)
      try {
  	    const data = await loginUser({
  	      email,
  	      password,
  	    });

        if (!data.auth_token) {
          alert(strings.wrong_pwd_email);
          setLoading(false)
          return;
        }


  	    setToken(data.auth_token);
        setUser(data.user)

        //history.push('/Profile')
    	}
    	catch (error) {
    		alert(error.message)
    	}

      setLoading(false)
    }

  }

    const getLanguage = () => {
      var lang = detectBrowserLanguage

      if (lang && lang.length >= 2) {
        return lang.substring(0, 2)
      }
      return ''
    }


    const validateEmail = (email) => {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    const validateRegister = () => {

      if (!validateEmail(email)) {
        alert(strings.invalid_email);
        return false;
      }

      if (password != password2) {
        alert(strings.password_not_equal);
        return false;
      }

      if (username === "") {
         alert(strings.enter_username);
          return false;
      }

      return true

    }


  const renderLoading = () => {
    return (
      <View style={{position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(128, 128, 128, 0.6)"}}


          >
            <ActivityIndicator style={{width:100, height:100}} color={SECONDARY_COLOR}/>

          </View>)
  }

    const renderConfirmRegister = () => {

    return (
        <View style={{position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(128, 128, 128, 0.7)"}}
          >
            <View style={{backgroundColor:'#fff', borderRadius:8, margin: Dimensions.get("window").width / 8, padding:16}}>

            <Text style={{color:'#555'}}>{strings.must_agree_pp}</Text>

            <TouchableOpacity onPress={() => this.goToPrivacyPolicy() }>
            <Text style={{textDecorationLine:'underline', color:'#2ba5f7'}}>> {strings.see_terms}</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => setAcceptEmail(!acceptEmail)} style={{flexDirection:'row', alignItems:'center', marginTop:16}}>

            <CheckBox checked={acceptEmail} onPress={() => setAcceptEmail(!acceptEmail)}/>
            <Text style={{color:'#555', marginLeft:8}}>{strings.agree_email}</Text>
            </TouchableOpacity>

            <View style={{flexDirection:'row', justifyContent:'space-around', marginTop:16}}>
              <Button title={Utils.capitalizeFirstLetter(strings.cancel)} style={{alignSelf:'center', marginRight:16, backgroundColor:'#ccc'}}
              onPress={() => setShowConfirmRegister(0)}
              />
              <Button title={Utils.capitalizeFirstLetter(strings.agree)} style={{alignSelf:'center', marginLeft:16}}
              onPress={() => handleSubmit()}/>
            </View>

            </View>

        </View>
      )
  }

    const renderCheckEmail = () => {

    return (
        <ImageBackground
        style={{
          width:Dimensions.get('window').width,
          height: Dimensions.get('window').height,
          justifyContent: "center",
          alignItems: "center"
        }}
        urlOnServer={'/imgs/background_login.jpg'}
      >

    <Text style={{color:'#555', borderRadius:16, backgroundColor:'#eee', padding:16}}>{strings.rc_web.confirm_email} Email: {email}</Text>


      </ImageBackground>


      )
  }



    if (loading)
      return renderLoading()


    if (checkEmail)
      return renderCheckEmail()
   // if (waitingForConfirmation)
     // return null

    return (
      <ImageBackground
        style={{width:Dimensions.get('window').width, height: Dimensions.get('window').height - 72}}
        urlOnServer={'/imgs/background_login.jpg'}
      >
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-between",
            width:400,
            alignSelf:'center'
          }}
        >
          <Image
            style={styles.icon}
            urlOnServer={'/imgs/icon_android.png'}
          />

          <Text style={styles.headerText}>
            {strings.login_desc}
          </Text>

           {isRegistering && <TextInput
              style={styles.input}
              autoCapitalize="none"
              onChangeText={username => setUsername(username)}
              autoCorrect={false}
              returnKeyType="next"
              placeholder={strings.username}
              placeholderTextColor="rgba(205,205,205,0.9)"
              value={username}
            />}

            <TextInput

              style={styles.input}
              autoCapitalize="none"
              onChangeText={email => setEmail(email)}
              autoCorrect={false}
              keyboardType="email-address"
              returnKeyType="next"
              placeholder={Utils.capitalizeFirstLetter(strings.your_email)}
              placeholderTextColor="rgba(205,205,205,0.9)"
              value={email}
            />

           <TextInput

              style={styles.input}
              returnKeyType="go"
              onChangeText={password => setPassword(password)}
              placeholder={Utils.capitalizeFirstLetter(strings.your_password)}
              placeholderTextColor="rgba(205,205,205,0.9)"
              secureTextEntry
              value={password}
            />

           {isRegistering && <TextInput

              style={styles.input}
              returnKeyType="go"
              onChangeText={password2 => setPassword2(password2)}
              placeholder={Utils.capitalizeFirstLetter(strings.repeat_password)}
              placeholderTextColor="rgba(205,205,205,0.9)"
              secureTextEntry
              value={password2}
            />}

            <TouchableOpacity
              style={styles.buttonContainer}
              onPress={() => {
                  handleSubmit()
                }
              }
            >
              <Text style={styles.buttonText}>
                {Utils.capitalizeFirstLetter(!isRegistering ? strings.login : strings.register)}
              </Text>
            </TouchableOpacity>



          <TouchableOpacity
            style={styles.switchContainer}
            onPress={() => setIsRegistering(!isRegistering)}
          >
            <Text style={styles.switch}>
              {isRegistering ? Utils.capitalizeFirstLetter(strings.already_have_account) : Utils.capitalizeFirstLetter(strings.register)}
            </Text>
          </TouchableOpacity>

          {!isRegistering && <a style={{alignSelf:'center'}} href='https://tricoton2.pingou.fr/password/request_new'>{strings.password_forgotten}</a>}



          <View style={{flex:1}}/>

        </ScrollView>




        {showConfirmRegister == 1 && renderConfirmRegister()}
      </ImageBackground>
  );

}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired
}



// define your styles
const styles = StyleSheet.create({

  form: {
    paddingHorizontal: 50
  },

  back: { position: "absolute", top: 0, left: 0 },

  icon: { width: 70, height: 70, alignSelf: "center", marginTop: 30 },

  switch: {
    color: SECONDARY_COLOR,
    alignSelf: "center",
    paddingHorizontal: 15,

    fontWeight: "bold",
    textDecorationLine:'underline'

  },
  headerText: {
    fontWeight: "bold",
    color: "red",
    fontSize: 18,
    alignSelf: "center",
    textAlign: "center",
    margin: 20,
    paddingHorizontal: 10
  },
  input: {
    height: 40,
    backgroundColor: "white",
    marginBottom: 10,
    padding: 10,
    color: "#555",
    borderRadius: 20,
    shadowColor: "#000",
    shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowOffset: { width: 1, height: 1 },
    elevation: 3
  },
  buttonContainer: {
    height: 40,
    backgroundColor: SECONDARY_COLOR,
    borderRadius: 20,
    shadowColor: "#000",
    shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowOffset: { width: 1, height: 1 },
    elevation: 3,
    paddingHorizontal: 40,
    alignSelf:'center',
    marginTop: 20,
    marginBottom:5,
    justifyContent: "center"
  },
  buttonText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
    alignSelf: "center"
  },
  loginButton: {
    backgroundColor: SECONDARY_COLOR,
    color: "#fff"
  },

  switchContainer: {
     alignSelf: "center",
              marginHorizontal: 20,
              marginBottom: 20,
              marginTop: 5,


    alignSelf: "center",
    height: 40,
    justifyContent:'center',
    alignItems:'center'
  },
  buttonSwitch: {
    textDecorationLine: "underline",
    textAlign: "center",
    color: "#555"
  },
  identification_required: {
    textAlign: "center",
    fontStyle: "italic",
    color: "#555",
    marginBottom: 20
  }
});
