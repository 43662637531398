import fetchClient from "../utils/http-common";
import { SERVER_API_URL } from '../config/Constants'

const findPartner = (game, onUploadProgress) => {


  /*if (project) {
    delete project.pdf_pattern
    delete project.pattern_url
  }*/

  return fetchClient.post(`${SERVER_API_URL}/lobby_games/find_match.json`, game, {
    onUploadProgress,
  });
};

const acceptGame = (lobbyGameId, userId, onUploadProgress) => {

  var game = {
    id: lobbyGameId,
    other_player_id: userId
  }
  return fetchClient.post(`${SERVER_API_URL}/lobby_games/accept_game.json`, game, {
    onUploadProgress,
  });
};

const askPlayerInBotGame = (lobbyGameId, userId, onUploadProgress) => {

  var game = {
    id: lobbyGameId,
    other_player_id: userId
  }
  return fetchClient.post(`${SERVER_API_URL}/lobby_games/ask_player_in_bot_game.json`, game, {
    onUploadProgress,
  });
};


const makeMove = (gameId, fen, lastMove, isWinningMove, onUploadProgress) => {

  var game = {
    board: fen,
    moves: lastMove,
    isWinningMove: isWinningMove
  }
  return fetchClient.post(`${SERVER_API_URL}/games/${gameId}/make_move.json`, game, {
    onUploadProgress,
  });
};

const getGame = (gameId, onUploadProgress) => {
  return fetchClient.get(`${SERVER_API_URL}/games/${gameId}.json`, {
    onUploadProgress,
  });
};

const createBotGame = (game, onUploadProgress) => {

  return fetchClient.post(`${SERVER_API_URL}/games/create_bot_game.json`, game, {
    onUploadProgress,
  });
};

const getLobbyGames = () => {

  return fetchClient.get(`${SERVER_API_URL}/lobby_games.json`);
};

const joinGame = (lobbyGameId) => {

  return fetchClient.get(`${SERVER_API_URL}/lobby_games/${lobbyGameId}/join_game.json`);
};


const gameTimeout = (gameId) => {

  return fetchClient.post(`${SERVER_API_URL}/games/${gameId}/timeout.json`);
};

const gameCancelled = (gameId) => {

  return fetchClient.post(`${SERVER_API_URL}/games/${gameId}/cancel.json`);
};

const getGamesHistory = () => {

  return fetchClient.get(`${SERVER_API_URL}/games/history.json`);
};

const confirmMove = (gameId) => {

  return fetchClient.post(`${SERVER_API_URL}/games/${gameId}/confirm_move.json`);
};

const checkMoveConfirmed = (gameId) => {

  return fetchClient.post(`${SERVER_API_URL}/games/${gameId}/check_move_confirmed.json`);
};

const leftGame = (gameId) => {

  return fetchClient.post(`${SERVER_API_URL}/games/${gameId}/left_game.json`);
};

const postChat = (gameId, message) => {

  return fetchClient.post(`${SERVER_API_URL}/games/${gameId}/post_chat.json`, { message: message });
};


export default {
  findPartner,
  acceptGame,
  getGame,
  makeMove,
  createBotGame,
  getLobbyGames,
  joinGame,
  gameTimeout,
  gameCancelled,
  getGamesHistory,
  confirmMove,
  checkMoveConfirmed,
  leftGame,
  askPlayerInBotGame,
  postChat
};
