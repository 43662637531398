import React from 'react';
import useToken from '../hooks/useToken';
import useUser from '../hooks/useUser';
import { useState, useEffect, useMemo, useRef } from 'react';
import { SERVER_API_URL } from '../config/Constants'
import Loading from '../components/Loading'
import ImageBackground from '../components/ImageBackground'
import { HEADER_HEIGHT } from '../config/Constants'
import GameUtils from '../utils/gameUtils'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Chessboard } from "react-chessboard";
import { Chess } from 'chess.js';
import PiecesImg from '../graphics/pieces'
import Utils from '../utils/utils';
//import Chess from '../utils/chess';

//import { Chess } from '../../node_modules/chess.js/dist/cjs/chess.js';
import SoundDing from '../sounds/ding.mp3';
import SoundFart from '../sounds/fart.mp3';
import SoundClick from '../sounds/click.wav';
import { getBestMove } from '../utils/ai'
import Timer from "../components/Timer"
import Toggle from "react-toggle";
import "react-toggle/style.css"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import strings from "../config/strings";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeOff, faGear, faVolumeHigh, faLightbulb, faPause, faEye, faHourglassHalf, faMessage, faEarthAsia, faRobot, faBackwardStep, faForwardStep, faTrophy, faThumbsDown, faBan, faUserNinja, faUserAstronaut, faUserGraduate, faUserDoctor, faUserInjured, faUserSecret, faUserShield } from '@fortawesome/free-solid-svg-icons'
import HelpModal from '../components/HelpModal';
import PauseModal from '../components/PauseModal';
import GameAvailable from '../components/GameAvailable';
import SimpleAlert from '../components/SimpleAlert';
import cable from '../channels/cable'
import GameChannel from '../channels/GameChannel'
import LobbyChannel from '../channels/LobbyChannel'
import Countdown from '../components/Countdown'
import ChatModal from '../components/ChatModal'
import ChatBubble from '../components/ChatBubble';
import BotSettingsModal from '../components/BotSettingsModal'

//import { useCable } from '../contexts/CableProvider';
//import { useChannel } from '@aersoftware/react-use-action-cable';
import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  //Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

const PIECES_TAKEN_HEIGHT = 40

export default function Game() {
  //const actionCable = useCable();
  //const { subscribe, unsubscribe, send } = useChannel(actionCable)

  const navigate = useNavigate();
  const audioFart = useMemo(() => new Audio(SoundFart), []);
  const audioDing = useMemo(() => new Audio(SoundDing), []);
  const audioClick = useMemo(() => new Audio(SoundClick), []);
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(true);
  const [isBotGame, setIsBotGame] = useState(false);
  const [loadingAI, setLoadingAI] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showPause, setShowPause] = useState(false);
  const [showGameAv, setShowGameAv] = useState(false);
  const [times, setTimes] = useState(false);
  //const [isWhite, setIsWhite] = useState(false);
  const [soundDisabled, setSoundDisabled] = useState(localStorage.getItem('soundDisabled') == 'true' ? true : false);
  const [myTurn, _setMyTurn] = useState(false);
  const [debugHistory, setDebugHistory] = useState(localStorage.getItem('user') ? 'user_id:' + JSON.parse(localStorage.getItem('user')).id + '\n' : '');
  const [lastMove, setLastMove] = useState([]);
  const [lastMoveAsString, setLastMoveAsString] = useState('');
  //const [fenBeforeMyMove, setFenBeforeMyMove] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
  const loadingGame = useRef(false)
  const myTurnRef = useRef(false)
  const fenBeforeMyMoveRef = useRef(false)
  const gameUtilsRef = useRef(false)
  const isWhiteRef = useRef(false);
  const isConnected = useRef(false);
  const tryToConnectAt = useRef(0);
  const lastPingAt = useRef(0);
  const lastInteractionAt = useRef(0);
  const needRefresh = useRef(false);
  const gameHistory = useRef([]);
  const currentHistoryPos = useRef(-1);
  const piecesTakenRef = useRef(null)
  const [gameToJoin, setGameToJoin] = useState(null);
  const haveSentOnExpire = useRef(false)
  const timerRef = useRef(null)
  const lobbyConnected = useRef(false)
  const [alertContent, setAlertContent] = useState(false)
  const [alertAskJoinContent, setAskJoinAlertContent] = useState(false)
  const [gameFinished, setGameFinished] = useState(false)
  const timeOffset = useRef(0)
  const timerCancelRef = useRef(null)
  const timerCheckConfirmMove = useRef(null)
  const timerJoinAskedRef = useRef(null)
  const timerGameAvailableRef = useRef(null)
  const opponentRef = useRef(null)
  const gameRef = useRef(null)
  const timerCheckIsUpToDateRef = useRef(null)
  const messageListRef = useRef([])

  const [showChat, setShowChat] = useState(false);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [showChatBubbleMessage, setShowChatBubbleMessage] = useState('');
  const [showBotSettings, setShowBotSettings] = useState(false)
  const botLevel = useRef(3)
  const [gameInThePast, setGameInThePast] = useState(null)
  const [movesInThePast, setMovesInThePast] = useState(null)
  const fire = new Event('fire');

  let { id } = useParams();
  let query = new URLSearchParams(useLocation().search);

  let paramIsBotGame = query.get("isBotGame");
  let paramWaitingForGameWithTime = query.get("waitingForGameWithTime");

  //const [ fen, setFen ] = useState(null);
  const [game, setGame] = useState(null);

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {

    if (paramIsBotGame)
      return


    const handleBeforeUnload = (event) => {
      // Custom behavior before the user leaves the page
      console.log('User is leaving the page');

      if (!gameFinished)
        GameUtils.leftGame(id)
      // If you want to show a confirmation dialog
      //   event.preventDefault();
      //  event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  const getRealTime = () => {
    return Date.now() - timeOffset.current
  }
  const setTimeOffset = (realTime) => {
    timeOffset.current = Date.now() - (realTime * 1000)
  }

  const saveToDebug = (txt, showTime = false) => {
    if (showTime) {
      var date = new Date();
      var hour = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      txt = hour + ':' + minutes + ':' + seconds + ' ' + txt;
    }
    setDebugHistory(prevDebugHistory => prevDebugHistory + txt + '\n')
    console.log(txt)
  }

  const handleLastMove = (lastMoveStr) => {
    const moves = lastMoveStr.split(',')
    var array = []
    const lastMove = [moves[0], moves[1], 'blue']
    array.push(lastMove)
    if (moves.length > 2) {
      const pushedPiece = [moves[1], moves[2], 'red']
      array.push(pushedPiece)
    }


    setLastMove(array)
    setLastMoveAsString(lastMoveStr)

    return array ? array : null
  }

  const handleLobbyUpdate = (data) => {
    //  alert(JSON.stringify(data) + '>>' + JSON.stringify(user))
    // alert('handleReceived' + JSON.stringify(data) + '>>' + user.id)
    console.log(data) // response received from rails

    if (!data || !data.action || !user)
      return

    /*if (data.action == 'game_starts' && (data.player1_id == user.id || data.player2_id == user.id)) {
      // alert('nav')

      try {
        if (audioDing)
          audioDing.play()
      }
      catch (e) {
      }
      navigate("/game/" + data.game_id);
    }*/
    // alert(user.id + '  ' + data.user_id)
    // alert(JSON.stringify(data))
    /* if (data.action == 'game_starts' && (data.player1_id === user.id || data.player2_id === user.id)) {
       setAlertContent({
         variant: '',
         text: 'Someone wants to play with you',
         textColor: "text-warning",
         icon: faTrophy,
         buttonText: "Play (in new tab)",
         buttonAction: () => {

           window.open('/game/' + data.game_id, '_blank')
           setAlertContent(false)}
        })
     }*/
    if (data.action == 'ask_player_game' && data.asked_to === user.id) {
      setAskJoinAlertContent({
        variant: '',
        text: 'Someone wants to play with you',
        textColor: "text-warning",
        icon: faTrophy,
        buttonText: "Play (in new tab)",
        buttonTimeOut: 15,
        buttonAction: () => {

          acceptGame({ id: data.lobby_id, user_id: data.user_id })
          //window.open('/game/' + data.game_id, '_blank')
          setAskJoinAlertContent(false)
        }
      })
      if (timerJoinAskedRef.current)
        clearTimeout(timerJoinAskedRef.current)
      timerJoinAskedRef.current = setTimeout(() => setAskJoinAlertContent(false), 15000)
    }

    if (data.action == 'game_starts' && gameToJoin && gameToJoin.unique_id == data.unique_id) {
      setGameToJoin(null)
    }

    if (data.action == 'game_new' && (data.time == paramWaitingForGameWithTime || paramWaitingForGameWithTime === -1 || data.time === -1)) {
      setGameToJoin(data)
      if (timerGameAvailableRef.current)
        clearTimeout(timerGameAvailableRef.current)

      timerGameAvailableRef.current = setTimeout(() => { setGameToJoin(null); }, 15000)
    }

  }


  useEffect(() => {
    if (!paramIsBotGame || !paramWaitingForGameWithTime) {
      return
    }
    const channel = new LobbyChannel({})


    // Subscribe to the server channel via the client.
    cable.subscribe(channel) // return channel itself for chaining



    // Handle incoming messages
    let unbindMessage = channel.on('message', msg => {
      // alert(JSON.stringify(msg))
      handleLobbyUpdate(msg)
      console.log(`${msg.name}: ${msg.text}`)
    }
    )


    // Cleanup function
    return () => {
      // Unsubscribe from the channel (results in a 'close' event)
      channel.disconnect()
      unbindMessage()
    }

  }, []);

  /*
  useEffect(() => {
    if (!paramIsBotGame || !paramWaitingForGameWithTime) {
      return
    }

    const subscription = consumer.subscriptions.create({
      channel: 'LobbyChannel',

    }, {
      connected: () => {
        lobbyConnected.current = true
      },
      disconnected: () => {

        },
      received: data => handleLobbyUpdate(data),
      ping: () => {  },
    })

    // Cleanup function
    return () => {
      //if (lobbyConnected.current) {
       // lobbyConnected.current = false
      // consumer.subscriptions.remove(subscription);
      //}
    }

  }, []);*/
  /*
    const lastMove () => {
      const moves = lastMoveAsString.split(',')
      var array = []
      const firstArrow = [moves[0], moves[1], 'blue']
      array.push(firstArrow)
      if (moves.length > 2) {
        const pushedPiece = [moves[1], moves[2], 'red']
        array.push(pushedPiece)
      }


     // setArrows(array)
     // setLastMoveAsString(lastMoveStr)

      return array ? array : null
    }*/

  const testBug = () => {
    var buggedGame = new Chess('N2P4/3r3p/5p2/2p1p3/5P2/4k1K1/6PP/7q w - - 0 31')
    var moves = buggedGame.moves({ verbose: true })
    for (var i = 0; i < moves.length; i++) {
      var child = moves[i]
      if (child.to.length !== 2) {
        console.log(child.before)
        console.log(child.after)
        alert('debug invalid to' + JSON.stringify(child))
      }
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    function handleMouseMove(event) {
      //console.log(`Mouse moved: X:${event.clientX}, Y:${event.clientY}`);
      lastInteractionAt.current = Date.now()
    }

    window.addEventListener('mousemove', handleMouseMove);

    // Be sure to remove event listener when the component unmounts
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount



  const playFart = () => {
    console.log("###### FAAAAAART ######")
    window.dispatchEvent(fire);
    try {
      if (audioFart && !soundDisabled)
        audioFart.play()
    }
    catch (e) {
    }
  }

  const onExpire = async () => {

    if (haveSentOnExpire.current)
      return
    await GameUtils.gameTimeout(id)
    setGameFinished(true)
    if (timerRef.current)
      clearTimeout(timerRef.current)
  }

  const confirmMove = async () => {
    await GameUtils.confirmMove(id)
  }

  const checkMoveConfirmed = async () => {
    await GameUtils.checkMoveConfirmed(id)
  }

  const showResult = (won, onTime = false) => {
    var text = won ? 'You won' : 'You lost'
    if (onTime)
      text += ' on time'

    //text += '\n. Like the game? Please upvote us on hacker news before it disappears in the sea of submissions!'

    setAlertContent({
      variant: won ? 'success' : 'danger',
      text: text,
      textColor: won ? "text-warning" : "text-danger",
      icon: won ? faTrophy : faThumbsDown,
      buttonText: "Go to Lobby",
      buttonAction: () => navigate('/')

    })
  }

  const handleReceived = (data) => {

    //attention cette function n'a pas acces aux dernieres updates, par exemple isWhite renverra false, car etait false au moment ou la fonction a ete creee
    saveToDebug('handleReceived' + JSON.stringify(data), true)
    //alert('handleReceived' + JSON.stringify(data))
    console.log(data) // response received from rails
    if (!data || !data.action) {
      saveToDebug('handleReceived bad data')
      return
    }

    if (data.action == 'game_aborted') {

      clearInterval(timerCancelRef.current)
      setAlertContent({
        variant: 'success',
        text: 'Game aborted',
        textColor: "text-warning",
        icon: faHourglassHalf,
        buttonText: "Go to Lobby",
        buttonAction: () => navigate('/'),
        noClose: true
      })
    }

    if (data.action == 'game_over') {
      setGameFinished(true)
      if (data.winner_id == user.id) {
        //alert('You won on time' + user.id)
        showResult(true, true)

      }
      else {
        //alert('You lost on time')
        showResult(false, true)

      }
    }

    if (data.action == 'new_message') {
      messageListRef.current = [...messageListRef.current, { user_id: data.user_id, message: data.message, created_at: data.created_at }]
      setMessageList(messageListRef.current)
      setHasNewMessage(true)
      setShowChatBubbleMessage(data.message)
      setTimeout(() => setShowChatBubbleMessage(''), 5000)

    }

    if (data.action == 'game_move') {

      setGameInThePast(null)
      setMovesInThePast(null)
      if (data.whos_next === user.id && timerCheckIsUpToDateRef.current)
        clearTimeout(timerCheckIsUpToDateRef.current)


      var gameTmp = new Chess(data.board)
      setGame(gameTmp)
      gameRef.current = gameTmp

      var playedFart = false
      if (GameUtils.countTotalPieces(fenBeforeMyMoveRef.current) != GameUtils.countTotalPieces(data.board)) {
        playedFart = true
        playFart()
      }
      //setFenBeforeMyMove(data.board)
      fenBeforeMyMoveRef.current = data.board

      if (data.whos_next == user.id && !myTurnRef.current && !playedFart) {
        try {
          if (audioClick && !soundDisabled)
            audioClick.play()
        }
        catch (e) {
        }
      }
      var myTurnTmp = false
      if (data.whos_next == user.id)
        myTurnTmp = true
      else
        myTurnTmp = false
      setMyTurn(myTurnTmp)

      if (data.whos_next == user.id && data.isWinningMove) {
        //alert('You lost')
        showResult(false, false)

      }
      if (data.last_move) {
        handleLastMove(data.last_move)


      }

      if (timerRef.current)
        clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => onExpire(), data.player_turn_expires_at - getRealTime())
      setTimes({ non_player_time: data.non_player_time, player_turn_expires_at: data.player_turn_expires_at })
      console.log('resetting game utils after ws message', isWhiteRef.current)
      gameUtilsRef.current = new GameUtils(gameTmp, isWhiteRef.current, isBotGame, myTurnTmp, data.last_move)

      setHistory(data.board, data.moves)

      if (data.whos_next == user.id)
        confirmMove()
    }

  }



  const setHistory = (board, moves) => {
    gameHistory.current = gameUtilsRef.current.constructHistory(moves)
    currentHistoryPos.current = -1
    piecesTakenRef.current = gameUtilsRef.current.getPiecesTaken(board)
    //alert(JSON.stringify(piecesTakenRef.current))
  }


  const getGame = async (onlyRedrawIfChange = false) => {

    if (loadingGame.current)
      return


    loadingGame.current = true
    if (!onlyRedrawIfChange)
      setLoading(true)
    try {
      var res = await GameUtils.getGame(id)
    }
    catch (e) {
      // alert(e.message)
      console.error(e)
      loadingGame.current = false
      return
    }

    if (onlyRedrawIfChange && !paramIsBotGame) {

      //  console.log('checking if up to date', gameRef.current, gameRef.current ? gameRef.current.fen() : 'not set', res.data.board)
      if (gameRef.current && gameRef.current.fen() && res.data.board && gameRef.current.fen() != res.data.board) {
        console.log('need refresh')
        window.location.reload()
      }
      else
        timerCheckIsUpToDateRef.current = setTimeout(() => getGame(true), 20000)
      loadingGame.current = false
      return

    }

    if (res.data.server_time) {
      setTimeOffset(res.data.server_time)
    }
    setLoading(false)

    saveToDebug('getting game' + JSON.stringify(res.data))

    if (res.data.messages) {
      messageListRef.current = res.data.messages
      setMessageList(messageListRef.current)
      //alert(JSON.stringify(messageListRef.current))
    }
    if (res.data.player1.id === user.id)
      opponentRef.current = res.data.player2
    else
      opponentRef.current = res.data.player1
    // alert('getting game' + JSON.stringify(res.data))
    var gameTmp = new Chess(res.data.board)
    setGame(gameTmp)
    gameRef.current = gameTmp
    fenBeforeMyMoveRef.current = res.data.board
    //setFenBeforeMyMove(res.data.board)

    const isBotGameTmp = !res.data.player2_id
    //alert(isBotGameTmp)
    if (!res.data.player2_id)
      setIsBotGame(isBotGameTmp)
    //alert(res.data.player1_color)

    if (!user)
      user = { id: -1 }
    const isWhiteTmp = (res.data.player1_id == user.id && res.data.player1_color == 0) || (res.data.player2_id == user.id && res.data.player1_color == 1)
    console.log("setting is white", isWhiteTmp)
    isWhiteRef.current = isWhiteTmp


    var myTurnTmp = res.data.whos_next === user.id
    setMyTurn(myTurnTmp)
    loadingGame.current = false


    if (res.data.last_move)
      handleLastMove(res.data.last_move)

    console.log('resetting game utils getGame')
    gameUtilsRef.current = new GameUtils(gameTmp, isWhiteTmp, isBotGameTmp, myTurnTmp)
    // testBug()
    // return
    //alert(JSON.stringify(res.data))

    if (timerRef.current)
      clearTimeout(timerRef.current)

    if (res.data.player_turn_expires_at - getRealTime() > 0)
      timerRef.current = setTimeout(() => onExpire(), res.data.player_turn_expires_at - getRealTime())


    setTimes({ non_player_time: res.data.non_player_time, player_turn_expires_at: res.data.player_turn_expires_at })

    if (!myTurnTmp && isBotGameTmp && !loadingAI && res.data.player1_id == user.id) {

      setLoadingAI(true)
      setTimeout(() => {

        getAiMove(gameTmp, isWhiteTmp ? 'b' : 'w', res.data.last_move)
      }
        , 500)
    }
    setHistory(res.data.board, res.data.moves)

    handleGameCancelled(res.data)
    //alert(checkIfHasMoves(gameTmp, res.data.last_move, isWhiteTmp ? 'w' : 'b'))
  }

  /*useEffect(() => {
    subscribe({
      channel: 'GameChannel',
      game_id: id,
    }, {
      received: (data) => handleReceived(data),
      // Custom callbacks can be added for 'initialized', 'connected', and 'disconnected'
    })
    return () => {
      unsubscribe()
    }
  }, [])*/

  /*
    const connectToSocket = () => {

      const subscription = consumer.subscriptions.create({
        channel: 'GameChannel', game_id: id,

      }, {
        connected: () => {
          saveToDebug('socket connected')
          if (needRefresh.current) {
            getGame()
            needRefresh.current = false
          }
          isConnected.current = true
          lastPingAt.current = Date.now()
        },
        disconnected: () => {

          saveToDebug('socket disconnected')

          isConnected.current = false
          needRefresh.current = true


        },
        ping: () => { lastPingAt.current = Date.now() },
        received: data => handleReceived(data),
      })
      return subscription
    }*/

  const checkPing = () => {

    if (isBotGame) {
      console.log("not checking ping")
      return
    }

    console.log("checking ping", lastPingAt.current, Date.now())
    //if haven't had a ping since 10 s, we refresh the page
    if (lastPingAt.current < Date.now() - (10 * 1000)) {
      console.log('no ping, reloading')


      var obj = null
      var str = localStorage.getItem('reloadInfo')
      if (str)
        obj = JSON.parse(str)

      //resetting to 0
      if (obj && obj.lastReload && obj.lastReload < Date.now() - (15 * 60 * 1000)
        && lastInteractionAt.current > Date.now() - (10 * 60 * 1000)) {

        obj.lastReload = Date.now()
        obj.nbReload = 0
        localStorage.setItem('reloadInfo', JSON.stringify(obj))
        window.location.reload()
      }
      ////prevents flooding the server
      else if (obj && obj.nbReload && obj.nbReload > 5) {
        console.log('too many reloads')
      }
      else if (lastInteractionAt.current > Date.now() - (10 * 60 * 1000)) {
        if (!obj)
          obj = {}
        if (!obj.nbReload)
          obj.nbReload = 0
        obj.nbReload++
        obj.lastReload = Date.now()
        localStorage.setItem('reloadInfo', JSON.stringify(obj))
        window.location.reload()
      }


    }
  }

  useEffect(() => {
    // Event handler to set the focus state
    const handleVisibilityChange = () => {

      if (!document.hidden) {
        console.log('Page is focused');
        checkPing()
      } else {
        console.log('Page is not focused');
      }
    };

    // Add the event listener
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);


  const checkCancel = async () => {
    await GameUtils.gameCancelled(id)

    if (gameHistory.current && gameHistory.current.length > 2)
      clearInterval(timerCancelRef.current)
  }




  useEffect(() => {

    if (paramIsBotGame)
      return
    const timer = setInterval(() => checkPing(), 10000)


    // Cleanup function
    return () => {
      clearInterval(timer);
    }
  }, []);

  useEffect(() => {


    // Cleanup function
    return () => {
      if (timerCancelRef.current)
        clearInterval(timerCancelRef.current);
    }

  }, []);

  const handleGameCancelled = (game) => {
    if (paramIsBotGame)
      return

    if (timerCancelRef.current)
      clearInterval(timerCancelRef.current)
    if (gameHistory.current && gameHistory.current.length < 2) {
      timerCancelRef.current = setInterval(() => checkCancel(), game.time === 60 ? 15000 : 35000)
    }
  }


  useEffect(() => {

    if (paramIsBotGame)
      return

    const channel = new GameChannel({ game_id: id })


    // Subscribe to the server channel via the client.
    cable.subscribe(channel) // return channel itself for chaining

    // Handle incoming messages
    let unbindMessage = channel.on('message', msg => {
      // alert(JSON.stringify(msg))
      handleReceived(msg)
      console.log(`${msg.name}: ${msg.text}`)
    }
    )


    let unbindPing = cable.on('keepalive', msg => {

      if (msg && typeof msg === 'number') {
        setTimeOffset(msg)
      }
      lastPingAt.current = Date.now()
    })
    // Or subscription close events


    // Cleanup function
    return () => {
      // Unsubscribe from the channel (results in a 'close' event)
      channel.disconnect()
      unbindMessage()
      unbindPing()
    }

  }, []);

  /*
    useEffect(() => {


      const subscription = connectToSocket()


      // Cleanup function
      return () => {
        if (isConnected.current) {
          isConnected.current = false
         consumer.subscriptions.remove(subscription);
        }
      }
    }, []); // Notice the empty array here. This tells React to run the effect only once, thus mimicking componentDidMount.
  */
  useEffect(() => {
    getGame()
  }, []); // Notice the empty array here. This tells React to run the effect only once, thus mimicking componentDidMount.


  useEffect(() => {

    if (paramIsBotGame)
      return

    timerCheckIsUpToDateRef.current = setTimeout(() => getGame(true), 20000)



    // Cleanup function
    return () => {
      clearTimeout(timerCheckIsUpToDateRef.current);
    }
  }, []);

  const setMyTurn = (value) => {
    myTurnRef.current = value
    _setMyTurn(value)
  }



  const getAiMove = async (game, color, lastMoveAsString) => {

    const fenBefore = game.fen()
    var lastMoveHasPushed = null
    var lastMoveObj = null
    if (lastMoveAsString && lastMoveAsString.length > 0) {
      lastMoveObj = lastMoveAsString.split(',')
      if (lastMoveObj.length > 2)
        lastMoveHasPushed = { from: lastMoveObj[1], to: lastMoveObj[2] }

      //alert(lastMoveAsString + ' ' + JSON.stringify(lastMoveHasPushed))
    }

    const gameTmp = new Chess(game.fen())
    const bestMoves = getBestMove(gameTmp, color, botLevel.current, lastMoveHasPushed)
   // alert(JSON.stringify(bestMoves))
//return
    var bestMove = bestMoves[0]
    if (!bestMove) {
      //console.log("last move", lastMove)
      var moveLeft = gameUtilsRef.current.checkIfHasMoves(game, lastMoveAsString, color)
      if (!moveLeft) {
        //alert('You won against AI')
        setAlertContent({
          text: 'You won against AI!',
          textColor: "text-warning",
          icon: faRobot,
          buttonText: "Go to Lobby",
          buttonAction: () => navigate('/')

        })
        return
      }
      bestMove = moveLeft

    }
    setLoadingAI(false)
    saveToDebug('AI PLAYED ' + JSON.stringify(bestMoves))


    game = new Chess(fenBefore)

    const hasMadeMove = await movePiece({
      from: bestMove.from,
      to: bestMove.to,
      promotion: "q",
    }, color, false, game, lastMoveAsString);


    //par exemple si on peut pousser la piece qui met en echec, mais ca remet en echec
    if (!hasMadeMove) {


      var moveLeft = gameUtilsRef.current.checkIfHasMoves(game, lastMoveAsString, color)

      const hasMadeMove = await movePiece({
        from: moveLeft.from,
        to: moveLeft.to,
        promotion: "q",
      }, color, false, game, lastMoveAsString);

    }

    if (GameUtils.countTotalPieces(fenBefore) != GameUtils.countTotalPieces(game.fen())) {
      playFart()
    }
  }



  const toggleSound = () => {
    localStorage.setItem('soundDisabled', !soundDisabled ? 'false' : 'true')
    setSoundDisabled(!soundDisabled)
  }


  const renderTimerContent = (text1, text2) => {

    return (
      <ListGroup.Item
        className="d-flex justify-content-between align-items-center bg-transparent"
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}>

        <View style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',

        }}>
          <Text style={{
            color: "white",
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 1,
            marginBottom: 6

          }}><FontAwesomeIcon icon={faHourglassHalf} style={{ color: "#FFD145", fontSize: 16 }} />  Opponent Time:
          </Text>
          <Text style={{
            color: "#FFE492",
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 1,
            marginBottom: 6

          }}>
            {text1}
          </Text>
        </View>
        <View style={{
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',

        }}>
          <Text style={{
            color: "white",
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 1,
            marginBottom: 6,


          }}><FontAwesomeIcon icon={faHourglassHalf} style={{ color: "#FFD145", fontSize: 16 }} />  My Time:</Text>
          <Text style={{
            color: "#FFE492",
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 1,
            marginBottom: 6,

          }}>{text2}</Text>
        </View>
      </ListGroup.Item>
    )

  }

  const renderTimers = () => {
    if (gameFinished || isBotGame) {
      return null
    }
    if (!times) {

      console.log('no timers')
      return
    }

    return (
      <ListGroup
        style={{ backgroundColor: "rgba(0,0,0,0.15)", borderRadius: 8, marginTop: 12 }}
        variant="flush" defaultActiveKey="#link2">
        {
          !lastMoveAsString ? renderTimerContent(Utils.convertSecondsToTimerMinutes(times.non_player_time / 1000), Utils.convertSecondsToTimerMinutes(times.non_player_time / 1000)) :
            myTurn ? renderTimerContent(Utils.convertSecondsToTimerMinutes(times.non_player_time / 1000), <Timer getRealTime={getRealTime} timestamp={times.player_turn_expires_at} />) :
              renderTimerContent(<Timer getRealTime={getRealTime} timestamp={times.player_turn_expires_at} />, Utils.convertSecondsToTimerMinutes(times.non_player_time / 1000))
        }

      </ListGroup>
    )
  }

  async function movePiece(piece, colorToCheck, isTest = false, currentGame, lastMoveAsString, allowInvalidMoves = false) {

    const fenBefore = currentGame.fen()
    setLoading(true)
    const result = gameUtilsRef.current.makeAMove(piece, colorToCheck, isTest, currentGame, lastMoveAsString, showResult, allowInvalidMoves);
    // illegal move
    if (!result) {
      saveToDebug('invalid move')
      setLoading(false)
      return false

    };
    //alert(JSON.stringify(result))



    var res = await GameUtils.makeMove(id, result.currentFen, result.lastMoveAsString, !result.hasMovesLeft ? true : false)
    
    
    console.log("Make move data", res)
    setLoading(false)
    if (res.data.error && res.data.error === 'timeout') {
      alert("You lost on time")
      setGameFinished(true)
      return
    }

    if (timerCheckConfirmMove.current)
      clearTimeout(timerCheckConfirmMove.current)

    if (!paramIsBotGame) {
      timerCheckConfirmMove.current = setTimeout(() => checkMoveConfirmed(), 12000)
      if (timerCheckIsUpToDateRef.current)
        clearTimeout(timerCheckIsUpToDateRef.current)
      timerCheckIsUpToDateRef.current = setTimeout(() => getGame(true), 20000)
    }

    var lastMoveObj = handleLastMove(result.lastMoveAsString)
    var tmpGame = new Chess(res.data.board)



    gameRef.current = tmpGame
    setGame(tmpGame)
    fenBeforeMyMoveRef.current = res.data.board
    //setFenBeforeMyMove(res.data.board)



    var myTurnTmp = false
    if (res.data.whos_next === user.id)
      myTurnTmp = true
    else
      myTurnTmp = false
    setMyTurn(myTurnTmp)
    console.log('resetting game utils after move', isWhiteRef.current)
    gameUtilsRef.current = new GameUtils(tmpGame, isWhiteRef.current, isBotGame, myTurnTmp, result.lastMoveAsString)


    if (isBotGame && GameUtils.countTotalPieces(fenBefore) != GameUtils.countTotalPieces(res.data.board)) {
      playFart()
    }
    if (isBotGame) {
      setHistory(res.data.board, res.data.moves)
      setGameInThePast(null)
      setMovesInThePast(null)
    }
    if (isBotGame && !res.data.whos_next) {
      setLoadingAI(true)
      setTimeout(() =>
        getAiMove(tmpGame, isWhiteRef.current ? 'b' : 'w', result.lastMoveAsString)
        , 500)
    }



    return true;
  }

  function isEnPassantMove(move) {
    const currentPiece = game.get(move.from);

    // Ensure the current piece is a pawn
    if (currentPiece.type !== 'p') return false;

    // En passant can only be a diagonal capture to an empty square
    if (move.to[0] === move.from[0]) return false;

    // The target square must be empty
    const pieceAtTarget = game.get(move.to);
    if (pieceAtTarget) return false;

    if (!lastMove)
      return false


    var lastMoveArray = lastMoveAsString.split(',')

    // Check if there was a last move and it was a pawn advancing two squares
    if (!lastMoveArray || lastMoveArray[0][1] === lastMoveArray[1][1]) return false;

    const lastMovedPiece = game.get(lastMoveArray[1]);


    if (!lastMovedPiece || lastMovedPiece.type !== 'p') return false;

    // Ensure the last lastMoveArray was two squares forward
    if (Math.abs(Number(lastMoveArray[0][1]) - Number(lastMoveArray[1][1])) !== 2) return false;

    // Ensure the capture is right after the opponent's pawn
    if (move.to[1] !== (currentPiece.color === 'w' ? '6' : '3')) return false;

    // Ensure that the last moved pawn is adjacent to the moving pawn's from-position
    if (Math.abs(move.from.charCodeAt(0) - lastMoveArray[1].charCodeAt(0)) !== 1) return false;

    return true;
  }


  async function onDrop(sourceSquare, targetSquare) {

    if (!myTurn) {

      return
    }
    if (currentHistoryPos.current != -1 && gameHistory.current && currentHistoryPos.current < gameHistory.current.length - 1) {
      setGameInThePast(null)
      setMovesInThePast(null)
      currentHistoryPos.current = -1
      return
    }
    if (isEnPassantMove({ from: sourceSquare, to: targetSquare })) {
      //alert("YOU SHALL NOT EN PASSANT!!!")
      setAlertContent({
        text: 'YOU SHALL NOT EN PASSANT!!!',
        textColor: "text-danger",
        icon: faBan,

      })
      return
    }

    const move = await movePiece({
      from: sourceSquare,
      to: targetSquare,
      promotion: "q",
    }, isWhiteRef.current ? 'w' : 'b', false, game, lastMoveAsString);
    // illegal move
    if (move === null) {
      saveToDebug('invalid move')
      return false

    };




    return true;
  }

  const mustShowBack = () => {

 //  if (gameHistory.current && gameHistory.current.length > 1 && (currentHistoryPos.current > 0 || currentHistoryPos.current === -1))
   //   return true
    if (gameHistory.current && gameHistory.current.length > 1 && (currentHistoryPos.current > 0 || currentHistoryPos.current === -1))
      return true
    return false
  }

  const mustShowNext = () => {

    if (currentHistoryPos.current != -1 && gameHistory.current && gameHistory.current.length >= 0 && currentHistoryPos.current < gameHistory.current.length - 1)
      return true
    return false
  }

  const historyNext = () => {

    if (currentHistoryPos.current == -1)
      currentHistoryPos.current = gameHistory.current.length - 1
    currentHistoryPos.current++

    // alert(JSON.stringify(gameHistory.current) + ' ' + currentHistoryPos.current)
    //if we got to the end

    var fen = gameHistory.current[currentHistoryPos.current].fen

    if (currentHistoryPos.current == gameHistory.current.length - 1) {

      if (myTurn && isWhiteRef.current && fen.indexOf(' w ') == -1) {
        fen = fen.replace(/ b /, ' w ')

      }
      else if (myTurn && !isWhiteRef.current && fen.indexOf(' b ') == -1) {
        fen = fen.replace(/ w /, ' b ')

      }

    }
    var gameTmp = new Chess(fen)

    if (currentHistoryPos.current == gameHistory.current.length) {
      setGame(gameTmp)
      setMovesInThePast(null)
    }
    else {
      setGameInThePast(gameTmp)
      setMovesInThePast(gameHistory.current[currentHistoryPos.current].moves)
    }

  }

  const historyBack = () => {

    if (currentHistoryPos.current == -1)
      currentHistoryPos.current = gameHistory.current.length - 1
    currentHistoryPos.current--

    //  alert(JSON.stringify(gameHistory.current) + ' ' + currentHistoryPos.current)

    var gameTmp = new Chess(gameHistory.current[currentHistoryPos.current].fen)
    setGameInThePast(gameTmp)
    setMovesInThePast(gameHistory.current[currentHistoryPos.current].moves)
  }

  const acceptGame = async (availableGame) => {
    setLoading(true)

    try {
      var res = await GameUtils.acceptGame(availableGame.id, availableGame.user_id)
      setLoading(false)
      if (res.data.error && res.data.error == 'already_started') {
        // alert("Another user already joined this game")
        setAlertContent({
          text: 'Another user already joined this game',
          textColor: "text-danger",
          icon: faUserNinja,

        })

        setGameToJoin(null)
        return
      }
    }
    catch (e) {
      alert(e.message)
      setLoading(false)
    }
    // alert('going to game ' + JSON.stringify(data))
    navigate("/game/" + res.data.id);
    window.location.reload();
    // alert(JSON.stringify(res))

  }

  const renderPiecesTaken = (position) => {

    if (!piecesTakenRef.current)
      return null
    var piecesTaken = []

    for (var key in piecesTakenRef.current) {
      if ((key === key.toUpperCase() && position === 'top' && isWhiteRef.current)
        || (key === key.toLowerCase() && position === 'top' && !isWhiteRef.current)
        || (key === key.toUpperCase() && position === 'bottom' && !isWhiteRef.current)
        || (key === key.toLowerCase() && position === 'bottom' && isWhiteRef.current)) {
        piecesTaken.push({ piece: key, nb: piecesTakenRef.current[key] })
      }

    }
    //alert(JSON.stringify(piecesTaken))
    const prefix = (isWhiteRef.current && position === 'top') || (!isWhiteRef.current && position === 'bottom') ? 'w' : 'b'
    return (
      <View style={{ marginTop: 6, marginBottom: 6 }}>
        <View style={{ flexDirection: 'row' }}>
          {piecesTaken.map((piece) => {
            let piecesImgArr = [];
            for (var i = 0; i < piece.nb; i++) {
              ///alert(prefix + piece.piece)

              let ImageElement = PiecesImg[prefix + piece.piece.toUpperCase()];
              piecesImgArr.push(

                <div style={{ width: 25, height: 25 }}>
                  <svg viewBox="0 0 40 40" width='25' height='25'>
                    <g>
                      {ImageElement}
                    </g>
                  </svg>
                </div>
              )
            }

            return piecesImgArr
          })}
        </View>
      </View>
    )

  }


  const renderToolBox = () => {
    return (
      <ButtonToolbar aria-label="Toolbar with button groups">
        <ButtonGroup className="me-2" aria-label="First group">
          <ToggleButton
            id="toggle-check"
            type="checkbox"
            variant="dark"
            checked={soundDisabled}
            value="1"
            onChange={toggleSound}
          >
            <FontAwesomeIcon icon={soundDisabled ? faVolumeOff : faVolumeHigh} />
          </ToggleButton>

          <Button
            variant="dark"
            onClick={() => setShowPause(true)}

          >

            <FontAwesomeIcon icon={faPause} />
          </Button>
          {
            mustShowBack() &&
            <Button
              variant="dark"
              onClick={() => {

                historyBack()

              }
              }>
              <FontAwesomeIcon icon={faBackwardStep} />
            </Button>
          }

          {
            mustShowNext() &&
            <Button
              variant="dark"
              onClick={() => {
                historyNext()
              }
              }>
              <FontAwesomeIcon icon={faForwardStep} />
            </Button>
          }


        </ButtonGroup>
        <View style={{
          flexDirection: 'row',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',

        }}>
          <Text style={{
            color: "#fff",
            fontSize: 14,
            fontWeight: 300,
            letterSpacing: 2,
            marginBottom: 6


          }}>{myTurn ? "My turn" : "Not my turn"}</Text>
        </View>

        <ButtonGroup className="me-2" aria-label="Second group">
          {
            isBotGame &&
            <Button
              variant="dark"
              onClick={() => {
                //window.dispatchEvent(fire);
                setShowBotSettings(true)
              }
              }>
              <FontAwesomeIcon icon={faGear} />
            </Button>
          }

          <Button
            variant="dark"
            onClick={() => {
              //window.dispatchEvent(fire);
              setShowHelp(true)
            }
            }>
            <FontAwesomeIcon icon={faLightbulb} />
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    )
  }

  const renderGameAvailable = () => {
    return (
      <Button variant="outline-warning" onClick={() => setShowGameAv(true)} className="mb-1 mt-2 ms-3 me-3" size="sm">
        <span style={{ flexDirection: 'row' }}>
          User Available! Elo: {gameToJoin.elo} <FontAwesomeIcon icon={faEye} style={{ color: "#FFD145", fontSize: 14 }} />  <Countdown time={15} />

        </span>
      </Button>


    )

  }

  const renderNormalGameInfos = () => {
    const icons = [faUserNinja, faUserAstronaut, faUserGraduate, faUserDoctor, faUserInjured, faUserSecret, faUserShield]
    var randomChosenIcon = icons[Math.floor(Math.random() * icons.length)]

    return (
      <View
        //className="mb-3"
        style={{
          flexDirection: isMobile ? "column" : 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: isMobile ? 0 : 24,
          marginBottom: 12
        }}>

        {gameToJoin && renderGameAvailable()}
        {gameToJoin && <GameAvailable gameToJoin={gameToJoin} acceptGame={() => acceptGame(gameToJoin)} show={showGameAv} setShow={setShowGameAv} />}
        <View
          className="m-3"
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 6
          }}>
          <FontAwesomeIcon icon={randomChosenIcon} style={{ fontSize: 16, color: "#fff", }} />
          <Text style={{
            color: "#fff",
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 2,
            marginHorizontal: 8
          }}>{opponentRef.current.name} ({opponentRef.current.elo})</Text>
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => { setShowChat(true); setHasNewMessage(false) }}>
            <FontAwesomeIcon icon={faMessage} style={{ fontSize: 16, color: hasNewMessage ? '#FECB2E' : "#fff", }} />
            {hasNewMessage && <Text style={{
              marginBottom: 3,
              marginLeft: 4,
              color: '#FECB2E',
              fontWeight: 900,
              letterSpacing: 2
            }}>(1)</Text>}
          </TouchableOpacity>

        </View>


      </View>
    )
  }


  const renderBotGameInfos = () => {
    return (
      <View
        //className="mb-3"
        style={{
          flexDirection: isMobile ? "column" : 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: isMobile ? 0 : 24,
          marginBottom: 12
        }}>

        {gameToJoin && renderGameAvailable()}
        {gameToJoin && <GameAvailable gameToJoin={gameToJoin} acceptGame={() => acceptGame(gameToJoin)} show={showGameAv} setShow={setShowGameAv} />}
        <View
          className="m-3"
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 6
          }}>
          <FontAwesomeIcon icon={faRobot} style={{ fontSize: 14, color: "#fff", }} />
          <Text style={{
            color: "#fff",
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 2,
            marginHorizontal: 8
          }}>Bot game</Text>
          {loadingAI && <ActivityIndicator size="small" color="#fff" />}
        </View>


      </View>
    )
  }
  var smallWidth = windowDimensions.width <= 480
  var prct = smallWidth ? 0.92 : 0.8
  var minSize = Math.min(windowDimensions.width, windowDimensions.height - HEADER_HEIGHT - (PIECES_TAKEN_HEIGHT * 2)) * prct
  minSize = minSize

  //alert(JSON.stringify(lastMove))
  if (loading) {
    return (<Loading />)
  }
  return (


    <View
      style={{
        flexDirection: isMobile ? 'column' : 'row',
        height: minSize + (PIECES_TAKEN_HEIGHT * 2)
      }}>
      <ChatModal show={showChat} gameId={id} messages={messageList} my_user_id={user.id} onHide={() => setShowChat(false)} />

      {showChatBubbleMessage && <ChatBubble message={showChatBubbleMessage} />}
      <HelpModal show={showHelp} onHide={() => setShowHelp(false)} />
      <PauseModal show={showPause} onHide={() => setShowPause(false)} onPause={() => alert('Pause not implemented yet, stay tuned.')} />
      {alertContent &&
        <SimpleAlert
          show={alertContent}
          onHide={() => setAlertContent(false)}
          alertContent={alertContent}
        />
      }

      {alertAskJoinContent && <SimpleAlert
        show={alertAskJoinContent}
        onHide={() => setAskJoinAlertContent(false)}
        alertContent={alertAskJoinContent}
      />
      }

      <BotSettingsModal show={showBotSettings} onHide={() => setShowBotSettings(false)} onSelect={(value) => { botLevel.current = value; setShowBotSettings(false) }} />
      <View style={{ flexDirection: isMobile ? 'column' : 'row', alignItems: 'center' }}>
        <View
          style={{
            //width: "100%",
            width: minSize + 'px'
          }}>

          {isBotGame && renderBotGameInfos()}
          {!isBotGame && opponentRef.current && renderNormalGameInfos()}

          {renderPiecesTaken('top')}


          {renderToolBox()}

          {game &&
            <Chessboard
              style={{
                width: minSize + 'px',
                height: minSize + 'px'
              }}
              id="BasicBoard"
              position={gameInThePast ? gameInThePast.fen() : game.fen()}
              onPieceDrop={onDrop}
              isDraggablePiece={(piece) => { return true }}
              //customArrows={[['a3', 'a5', 'red'], ['g1', 'f3']]}
              customBoardStyle={{
                boxShadow: "0 5px 25px rgba(0, 0, 0, 0.5)",
                borderRadius: "6px",
              }}
              customDropSquareStyle={{ boxShadow: "inset 0 0 1px 4px rgba(255, 255, 0, 0.5)" }}
              //customLightSquareStyle={{ backgroundColor: "rgba(255, 255, 0, 0.4)" }}
              //customDarkSquareStyle={{ backgroundColor: "rgba(110, 15, 18, 0.6)" }}
              customLightSquareStyle={{ backgroundColor: "#D1A038" }}
              customDarkSquareStyle={{ backgroundColor: "#A1291F" }}
              boardOrientation={isWhiteRef.current ? 'white' : 'black'}
              customArrows={movesInThePast ? movesInThePast : lastMove}

            />}
          {renderPiecesTaken('bottom')}
          {renderTimers()}
        </View>
        <View>


        </View>
      </View>




    </View>



  );
}

