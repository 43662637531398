import { Chess } from 'chess.js';
import GameUtils from './gameUtils';

var STACK_SIZE = 100; // maximum size of undo stack

var positionCount;



/*
 * Piece Square Tables, adapted from Sunfish.py:
 * https://github.com/thomasahle/sunfish/blob/master/sunfish.py
 */

var weights = { p: 100, n: 580, b: 300, r: 479, q: 929, k: 60000, k_e: 60000 };
var pst_w = {
  p: [
    [100, 100, 100, 100, 105, 100, 100, 100],
    [78, 83, 86, 73, 102, 82, 85, 90],
    [7, 29, 21, 44, 40, 31, 44, 7],
    [-17, 16, -2, 15, 14, 0, 15, -13],
    [-26, 3, 10, 9, 6, 1, 0, -23],
    [-22, 9, 5, -11, -10, -2, 3, -19],
    [-31, 8, -7, -37, -36, -14, 3, -31],
    [0, 0, 0, 0, 0, 0, 0, 0],
  ],
  n: [
    [-66, -53, -75, -75, -10, -55, -58, -70],
    [-3, -6, 100, -36, 4, 62, -4, -14],
    [10, 67, 1, 74, 73, 27, 62, -2],
    [24, 24, 45, 37, 33, 41, 25, 17],
    [-1, 5, 31, 21, 22, 35, 2, 0],
    [-18, 10, 13, 22, 18, 15, 11, -14],
    [-23, -15, 2, 0, 2, 0, -23, -20],
    [-74, -23, -26, -24, -19, -35, -22, -69],
  ],
  b: [
    [-59, -78, -82, -76, -23, -107, -37, -50],
    [-11, 20, 35, -42, -39, 31, 2, -22],
    [-9, 39, -32, 41, 52, -10, 28, -14],
    [25, 17, 20, 34, 26, 25, 15, 10],
    [13, 10, 17, 23, 17, 16, 0, 7],
    [14, 25, 24, 15, 8, 25, 20, 15],
    [19, 20, 11, 6, 7, 6, 20, 16],
    [-7, 2, -15, -12, -14, -15, -10, -10],
  ],
  r: [
    [35, 29, 33, 4, 37, 33, 56, 50],
    [55, 29, 56, 67, 55, 62, 34, 60],
    [19, 35, 28, 33, 45, 27, 25, 15],
    [0, 5, 16, 13, 18, -4, -9, -6],
    [-28, -35, -16, -21, -13, -29, -46, -30],
    [-42, -28, -42, -25, -25, -35, -26, -46],
    [-53, -38, -31, -26, -29, -43, -44, -53],
    [-30, -24, -18, 5, -2, -18, -31, -32],
  ],
  q: [
    [6, 1, -8, -104, 69, 24, 88, 26],
    [14, 32, 60, -10, 20, 76, 57, 24],
    [-2, 43, 32, 60, 72, 63, 43, 2],
    [1, -16, 22, 17, 25, 20, -13, -6],
    [-14, -15, -2, -5, -1, -10, -20, -22],
    [-30, -6, -13, -11, -16, -11, -16, -27],
    [-36, -18, 0, -19, -15, -15, -21, -38],
    [-39, -30, -31, -13, -31, -36, -34, -42],
  ],
  k: [
    [4, 54, 47, -99, -99, 60, 83, -62],
    [-32, 10, 55, 56, 56, 55, 10, 3],
    [-62, 12, -57, 44, -67, 28, 37, -31],
    [-55, 50, 11, -4, -19, 13, 0, -49],
    [-55, -43, -52, -28, -51, -47, -8, -50],
    [-47, -42, -43, -79, -64, -32, -29, -32],
    [-4, 3, -14, -50, -57, -18, 13, 4],
    [17, 30, -3, -14, 6, -1, 40, 18],
  ],

  // Endgame King Table
  k_e: [
    [-50, -40, -30, -20, -20, -30, -40, -50],
    [-30, -20, -10, 0, 0, -10, -20, -30],
    [-30, -10, 20, 30, 30, 20, -10, -30],
    [-30, -10, 30, 40, 40, 30, -10, -30],
    [-30, -10, 30, 40, 40, 30, -10, -30],
    [-30, -10, 20, 30, 30, 20, -10, -30],
    [-30, -30, 0, 0, 0, 0, -30, -30],
    [-50, -30, -30, -30, -30, -30, -30, -50],
  ],
};
var pst_b = {
  p: pst_w['p'].slice().reverse(),
  n: pst_w['n'].slice().reverse(),
  b: pst_w['b'].slice().reverse(),
  r: pst_w['r'].slice().reverse(),
  q: pst_w['q'].slice().reverse(),
  k: pst_w['k'].slice().reverse(),
  k_e: pst_w['k_e'].slice().reverse(),
};

var pstOpponent = { w: pst_b, b: pst_w };
var pstSelf = { w: pst_w, b: pst_b };



const getPiecePosition = (game, piece) => {
  for (let row = 8; row >= 1; row--) {
    // Loop over each column (a through h)
    for (let col = 'a'.charCodeAt(0); col <= 'h'.charCodeAt(0); col++) {
      let position = String.fromCharCode(col) + row;
      let tmpPiece = game.get(position);
      // If a piece exists and is the white king, print position
      if (tmpPiece && piece.type === tmpPiece.type && piece.color === tmpPiece.color) {
        return position;
      }
    }
  }
  return null
}

const checkIfInCheck = (game, color) => { 
  var kingPos = getPiecePosition(game, {type: 'k', color: color})
  //alert('checking king pos ' + kingPos)
  if (game.isAttacked(kingPos, color === 'w' ? 'b' : 'w')) {
    return true
  }
  return false
}



function isSumoCheckmate(game, color, move, piecePushed) {
  if (game.fen() === 'r4bnr/p2bpppp/2nkN3/1B1Q4/P2pP3/2P1PN2/5PPP/2B2RK1 w - - 2 21' && move.from === 'a2' && move.to === 'd5')
    alert('found it')
  if (!game.isCheck())
    return

  
//  var gameTmp = new Chess(game.fen())
  var gameUtils = new GameUtils(game, color === 'w', true, true)
    
  var lastMoveAsString = move.from + ',' + move.to
  if (piecePushed)
    lastMoveAsString += ',' + piecePushed.posAfterPush
  var hasMove = gameUtils.checkIfHasMoves(game, lastMoveAsString, color === 'w' ? 'b' : 'w', true)

 // if (move.to === 'e1')
   // alert('checking has move '  + JSON.stringify(hasMove) + ' ' + game.fen())
  if (hasMove)
    return false


  //console.log("AI: checkmate detected", move, game.fen())
  return true
}
/*
 * Evaluates the board at this point in time,
 * using the material weights and piece square tables.
 */

//j'ai commenté les deux // + pstSelf[move.color][pieceCaptured][to[0]][to[1]];

//depth est inversé, plus c'est elevé plus c'est le debut
function evaluateBoard(game, move, prevSum, color, pieceCaptured, mustDebug = false, piecePushed = null, depth) {


  if (isSumoCheckmate(game, move.color, move, piecePushed)) {
    
   // alert('is checkmate ' + JSON.stringify(move) + ' ' + game.fen())
   
   // if (move.from == 'c3' && move.to == 'e1')
    //alert('checking queen mate')
    // Opponent is in checkmate (good for us)
    //console.log('checking colorr, )
    //if (move.color == color && checkIfInCheck(game, color ? 'w' : 'b')) {
    if (move.color == color) {
      //if (move.to === 'e8')
      //alert(JSON.stringify(game.fen()))
      return -(10 ** 10) * depth;
      
    }
    // Our king's in checkmate (bad for us)
    else {
     // if (move.to === 'e8')
      //alert(JSON.stringify(game.fen()))
      return 10 ** 10* depth;
    }
  }

  if (game.isDraw() || game.isThreefoldRepetition() || game.isStalemate())
  {
    return 0;
  }

  if (game.inCheck()) {
    // Opponent is in check (good for us)
    if (move.color === color) {
      prevSum += 50;
    }
    // Our king's in check (bad for us)
    else {
      prevSum -= 50;
    }
  }

  var from = [
    8 - parseInt(move.from[1]),
    move.from.charCodeAt(0) - 'a'.charCodeAt(0),
  ];
  var to = [
    8 - parseInt(move.to[1]),
    move.to.charCodeAt(0) - 'a'.charCodeAt(0),
  ];
  if(isNaN(to[0])) {
    alert("Error: to[0] is NaN. move.to[1] is " + move.to[1] + '->' + JSON.stringify(move));
}
  // Change endgame behavior for kings
  if (prevSum < -1500) {
    if (move.piece === 'k') {
      move.piece = 'k_e';
    }
    // Kings can never be captured
    // else if (move.captured === 'k') {
    //   move.captured = 'k_e';
    // }
  }

  if (pieceCaptured) {
  //  console.log("AI: begin capture")
    if (mustDebug)
      console.log("AI: captured " + JSON.stringify(pieceCaptured))
    // Opponent piece was captured (good for us)
    if (move.color === color) {
      prevSum +=
        weights[pieceCaptured] 
        + pstOpponent[move.color][pieceCaptured][to[0]][to[1]];
    }
    // Our piece was captured (bad for us)
    else {
      prevSum -=
        weights[pieceCaptured]
        + pstSelf[move.color][pieceCaptured][to[0]][to[1]];
    }
   // console.log("AI: end capture")
  }

  if (move.flags.includes('p')) {
    // NOTE: promote to queen for simplicity
    move.promotion = 'q';
    // Our piece was promoted (good for us)
    if (move.color === color) {
      prevSum -=
        weights[move.piece] + pstSelf[move.color][move.piece][from[0]][from[1]];
      prevSum +=
        weights[move.promotion] 
        + pstSelf[move.color][move.promotion][to[0]][to[1]];
    }
    // Opponent piece was promoted (bad for us)
    else {
      prevSum +=
        weights[move.piece] + pstSelf[move.color][move.piece][from[0]][from[1]];
      prevSum -=
        weights[move.promotion] +
        pstSelf[move.color][move.promotion][to[0]][to[1]];
    }
  } else {
    // The moved piece still exists on the updated board, so we only need to update the position value
    if (move.color !== color) {
      prevSum += pstSelf[move.color][move.piece][from[0]][from[1]];
      prevSum -= pstSelf[move.color][move.piece][to[0]][to[1]];
    } else {
      prevSum -= pstSelf[move.color][move.piece][from[0]][from[1]];

     // console.log("AI", move.piece, to[0], to[1])
      prevSum += pstSelf[move.color][move.piece][to[0]][to[1]];
    }
  }

  return prevSum;
}


function nextStep(start, end, isKnight = false) {
  const columns = {'a': 1, 'b': 2, 'c': 3, 'd': 4, 'e': 5, 'f': 6, 'g': 7, 'h': 8};

  const sx = columns[start[0]];
  const sy = parseInt(start[1]);
  
  const ex = columns[end[0]];
  const ey = parseInt(end[1]);

  // Calculating direction of movement.
  let dx = ex - sx;
  let dy = ey - sy;

  if (sx === ex) {
      dy = ey < sy ? -1 : 1; // Moving up or down
  } else if (sy === ey) {
      dx = ex < sx ? -1 : 1; // Moving left or right
  } else {// if (Math.abs(dx) === Math.abs(dy)) {
      dx = ex < sx ? -1 : 1; // Moving diagonally left or right
      dy = ey < sy ? -1 : 1; // Moving diagonally up or down
  }
//alert(dx + ' ' + dy + ' ' + end[0] + ' ' + end[1]
  if (isKnight)
    dy = 0
  if (dx < 0 && end[0] === 'a' || dx > 0 && end[0] === 'h'
      || dy < 0 && end[1] === '1' || dy > 0 && end[1] === '8') {
    
    return null
  }

  

  // Calculating the next move.
  const columnLetter = Object.keys(columns).find(key => columns[key] === ex + dx);
  const nextMove = columnLetter + (ey + dy);
 
  return nextMove;
}


function convertPawnsToQueens(game) {

  let charArray = ['a','b','c','d','e','f','g','h'];


  //check all squares on the edges (a1 to h1, a8 to h8)
  for (var i = 0; i < charArray.length; i++) {

    var piece = game.get(charArray[i] + '1')
    if (piece && piece.type === 'p' && piece.color === 'b')
    {
      game.put({type: 'q', color: 'b'}, charArray[i] + '1');
    }

    piece = game.get(charArray[i] + '8')
    if (piece && piece.type === 'p' && piece.color === 'w')
    {
      game.put({type: 'q', color: 'w'}, charArray[i] + '8');
    }
  
  }
}

function checkHasPawnThatShouldBeQueens(game) {

  let charArray = ['a','b','c','d','e','f','g','h'];


  //check all squares on the edges (a1 to h1, a8 to h8)
  for (var i = 0; i < charArray.length; i++) {

    var piece = game.get(charArray[i] + '1')
    if (piece && piece.type === 'p' && piece.color === 'b')
    {
      return true
    }

    piece = game.get(charArray[i] + '8')
    if (piece && piece.type === 'p' && piece.color === 'w')
    {
      return true
    }
  
  }
  return false
}
/*
 * Performs the minimax algorithm to choose the best move: https://en.wikipedia.org/wiki/Minimax (pseudocode provided)
 * Recursively explores all possible moves up to a given depth, and evaluates the game board at the leaves.
 *
 * Basic idea: maximize the minimum value of the position resulting from the opponent's possible following moves.
 * Optimization: alpha-beta pruning: https://en.wikipedia.org/wiki/Alpha%E2%80%93beta_pruning (pseudocode provided)
 *
 * Inputs:
 *  - game:                 the game object.
 *  - depth:                the depth of the recursive tree of all possible moves (i.e. height limit).
 *  - isMaximizingPlayer:   true if the current layer is maximizing, false otherwise.
 *  - sum:                  the sum (evaluation) so far at the current layer.
 *  - color:                the color of the current player.
 *
 * Output:
 *  the best move at the root of the current subtree.
 */
function minimax(game, depth, alpha, beta, isMaximizingPlayer, sum, color, lastMoveHasPushed = null, mustDebug = false) {
  positionCount++;


  //convertPawnsToQueens(game);
  var children = game.uglyMoves({ });


  //  IMPORTANT, REMETTRE LE RANDOM ENSUITE
  // Sort moves randomly, so the same move isn't always picked on ties
  /*children.sort(function (a, b) {
    return 0.5 - Math.random();
  });
  */
  children.sort(function (a, b) {
    return Math.random() - 0.5;
  });

  
  var tmpArray = children.map((move) => game._makePretty(move))
  //remove invalid moves
  var validMoves = []
  for (var i = 0; i < tmpArray.length; i++) {

    var child = tmpArray[i]

  //console.log("AI: checking move " + child.from + ' ' + child.to)
    //remove en passant
    if (child.flags.includes('e')) {
      continue
    }
    if (child.piece === 'k') {
      validMoves.push(child)
      continue
    }
    //remove moves that have a piece blocking behind
    if (game.get(child.to)) {
      var next = nextStep(child.from, child.to, child.piece === 'n')
      if (next) {
        var nextSquare = game.get(next)
        if (nextSquare) {
          continue
        }
      }
    }
    //si piece pas virée du bord
    if (child.captured && next) {
    //  alert(JSON.stringify(child))
      child.piecePushed = {...game.get(child.to)}
      child.piecePushed.posAfterPush = next
      child.captured = null
 
      
      if (next && child.piecePushed.type === 'p' && ((child.piecePushed.color === 'w' && next[1] === '8') || (child.piecePushed.color === 'b' && next[1] === '1'))) {
       // alert('pushing opponent making queen' + JSON.stringify(child))
        child.isMakingOpponentQueen = true
      }
    }

    if (lastMoveHasPushed && lastMoveHasPushed.from === child.to && lastMoveHasPushed.to === child.from) {
      if (mustDebug) {
        console.log("AI: skipping move, cannot push back " + JSON.stringify(child) + '-> ' + JSON.stringify(lastMoveHasPushed))
       
      }
      continue
    } 

    validMoves.push(child)
    //alert('child:' + JSON.stringify(child))
    //return

    //transforme en dame, shouldn't happen
    if (child.to.length !== 2) {
      console.log("Promotion? " + JSON.stringify(child))
      if (!child.piece === 'p') {
        alert("invalid promotion")
      }
      alert("wrong promo " + JSON.stringify(child) + ' before:' + child.before + ' after:' + child.after)
      //child.piece = 'q'
     // child.to = child.from
    }
  }
 
  children = validMoves
  var currMove;
  // Maximum depth exceeded or node is a terminal node (no children)
  if (depth === 0 || children.length === 0) {
    return [null, sum];
  }
//  IMPORTANT, REMETTRE LE RANDOM ENSUITE EN HAUT
  // Find maximum/minimum from list of 'children' (possible moves)
  var maxValue = Number.NEGATIVE_INFINITY;
  var minValue = Number.POSITIVE_INFINITY;
  var bestMove;
  for (var i = 0; i < children.length; i++) {
    currMove = children[i];

    
    //alert(JSON.stringify(currMove))

    if (mustDebug) {
      console.log("AI: checking move " + currMove.from + ' ' + currMove.to)
      console.log("AI fen: " + game.fen()) 
    }

    var previousFen = game.fen()
    //alert(JSON.stringify(currMove))
    //var currPrettyMove = game._makePretty(currMove);
    // Note: in our case, the 'children' are simply modified game states
    var currPrettyMove
    
    try {
      currPrettyMove = game.move(currMove);
    }
    catch (e) {
      
      console.log("AI: invalid move " + JSON.stringify(currMove) + ' ' + game.fen())
      console.log(game.ascii())
      alert("AI: invalid move " + JSON.stringify(currMove) + ' ' + game.fen())
      continue
    }
   // if (!currMove.captured)
    //  currPrettyMove.captured = null

    lastMoveHasPushed = null
    var cannotPutPiece = false
    if (currMove.piecePushed) {


      lastMoveHasPushed = {from: currMove.to, to: currMove.piecePushed.posAfterPush}
      if (mustDebug)
        console.log("AI: piece pushed to " + currMove.piecePushed.posAfterPush)
      //game.remove(currMove.to)


      if (currMove.isMakingOpponentQueen) {
       // alert('isMakingOpponentQueen' + JSON.stringify(currMove))
        currMove.piecePushed.type = 'q'
        //console.log('replacing with queen', currMove.piecePushed)
        //alert('replacing with Q')
      }
     /* if (checkHasPawnThatShouldBeQueens(game)) {
        console.log('has pawn that should be queen')

      }
      else {
        console.log('no pawn that should be queen')
      }*/
     
      var piecePutRes = game.put(currMove.piecePushed, currMove.piecePushed.posAfterPush)
    /*  if (checkHasPawnThatShouldBeQueens(game)) {
        console.log('wut?', currMove)
        alert('wut?')
      }*/
      if (!piecePutRes) {
        cannotPutPiece = true
        //cannot put piece, probably king is in check
        //alert('AI: piece put failed' + JSON.stringify(currMove.piecePushed) + ' to ' + currMove.piecePushed.posAfterPush)
      }
    }
    else if (mustDebug && currMove.captured) {
      console.log("AI: piece out of board or eaten by king")
    }
    else if (mustDebug) {
      console.log("AI: no piece pushed")
    }

   // if (currMove.piecePushed)
     // alert('has removed and put')

    var newSum

    if (currMove.isMakingOpponentQueen)
    {
      if (currPrettyMove.color === color) {
        newSum = sum -= 800;
      }
      else {
        newSum = sum += 800;
      }
    }

   
    if (cannotPutPiece)
    {
      if (currPrettyMove.color === color) {
        newSum = sum -= 50000;
      }
      else {
        newSum = sum += 50000;
      }
    }
    else
      newSum = evaluateBoard(game, currPrettyMove, sum, color, currMove.captured, mustDebug, currMove.piecePushed, depth);
      /*game.undo();
      if (currMove.piecePushed && !cannotPutPiece) {
        game.remove(currMove.piecePushed.posAfterPush)
        game.put(currMove.piecePushed, currMove.to)
      }
    
      continue*/
      var [childBestMove, childValue] = [null, null]
      if (newSum > -10000 && newSum < 10000) {
          [childBestMove, childValue] = minimax(
                  game,
                  depth - 1,
                  alpha,
                  beta,
                  !isMaximizingPlayer,
                  newSum,
                  color,
                  lastMoveHasPushed,
                  mustDebug
                );
      }
      else
      {
        childValue = isMaximizingPlayer ? (10**10) * depth : -(8**8) * depth
      }

    
    game.undo();
    if (currMove.piecePushed && !cannotPutPiece) {
      game.remove(currMove.piecePushed.posAfterPush)
      game.put(currMove.piecePushed, currMove.to)
    }


    if (previousFen !== game.fen()) {
     // alert('GAME not reset correctly')
      game = new Chess(previousFen)
    }

    if (isMaximizingPlayer) {
      if (childValue > maxValue) {
        maxValue = childValue;
        bestMove = currPrettyMove;
      }
      if (childValue > alpha) {
        alpha = childValue;
      }
    } else {
      if (childValue < minValue) {
        minValue = childValue;
        bestMove = currPrettyMove;
      }
      if (childValue < beta) {
        beta = childValue;
      }
    }

    // Alpha-beta pruning
    if (alpha >= beta) {
      break;
    }
  }
  if (isMaximizingPlayer) {
    return [bestMove, maxValue];
  } else {
    return [bestMove, minValue];
  }
}

/*
 * Calculates the best legal move for the given color.
 */
export function getBestMove(game, color, depth, lastMoveHasPushed = null) {
  positionCount = 0;

  var currSum = 0
  if (color === 'b') {
    currSum = 50
  } else {
    currSum = -50
  }
  var d = new Date().getTime();
  var [bestMove, bestMoveValue] = minimax(
    game,
    depth,
    Number.NEGATIVE_INFINITY,
    Number.POSITIVE_INFINITY,
    true,
    currSum,
    color,
    lastMoveHasPushed
  );
  var d2 = new Date().getTime();
  var moveTime = d2 - d;
  var positionsPerS = (positionCount * 1000) / moveTime;

 

  return [bestMove, bestMoveValue];
}
