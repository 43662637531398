import React from 'react';
import useToken from '../hooks/useToken';
import useUser from '../hooks/useUser';
import { useState, useEffect, useMemo, useRef } from 'react';
import ImageBackground from '../components/ImageBackground'
import { HEADER_HEIGHT } from '../config/Constants'
import GameUtils from '../utils/gameUtils'
import { useParams } from 'react-router-dom';

import { Chessboard } from "react-chessboard";

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';



export default function Board() {
  

  const onDrop = (sourceSquare, targetSquare) => {
    
    alert(1)
        }

        var width = 400
        var heigh = 400
        var minSize = 400
  return(
 
   

    <View >

    <Chessboard  
  


    
    />
 
    </View>
    
  
     




  );
}

