import fetchClient from "../utils/http-common";
import { SERVER_API_URL } from '../config/Constants'

const getUser = (id, onUploadProgress = (event) => {
  //setProgress(Math.round((100 * event.loaded) / event.total));
  }) => {


  return fetchClient.get(`${SERVER_API_URL}/users/${id}.json`, {
    onUploadProgress,
  });
};


export default {
  getUser,
};
