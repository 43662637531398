import React, { Component } from "react";
import strings from "../config/strings";
import Image from "../components/Image"
import Utils from "../utils/utils";
import { SECONDARY_COLOR } from "../config/styles";
import { useState, useEffect, useMemo, useRef } from 'react';
import GameService from "../services/GameService";
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faHourglassHalf, faChess, faArrowRight, faUserSecret, faEye, faBell, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import useUser from '../hooks/useUser';
import moment from 'moment';
import LobbyGames from '../components/LobbyGames'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

const { width, height } = Dimensions.get("window");
const NB_SHOWN = 6

export default function Lobby() {
  const [loading, setLoading] = useState(false)
  const [lobbyGames, setLobbyGames] = useState([])
  const [showLobbyGame, setShowLobbyGame] = useState(false)
  const { user, setUser } = useUser();

  const getLobby = async () => {


    setLoading(true)
    try {
      var res = await GameService.getLobbyGames()
    }
    catch (e) {
      alert(e.message)
      console.error(e)
      return
    }
    //alert(JSON.stringify(res.data))
    setLoading(false)

    if (res.status == 200) {
      var lobby_games = res.data
      if (lobby_games.length > 0) {

        //delete our own games
        var items = lobby_games.filter((game) => game.user.id !== user.id)
        if (items && items.length > 0) {
          //remove duplicates and keep only one
          
          items = items.reduce((acc, game) => {
            const key = `${game.user.id}-${game.time}`;
            if (!acc.map.has(key)) {
                acc.map.set(key, true);
                acc.filtered.push(game);
            }
            return acc;
        }, { map: new Map(), filtered: [] }).filtered;
          //lobby_games = lobby_games.filter((game) => !lobbyGames.find((g) => g.user.id === game.user.id && g.time === game.time))
          items = items.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }


        setLobbyGames(items)
      }
    }
  }

 

  const joinGame = async (lobbyGameId) => {


    setLoading(true)
    try {
      var res = await GameService.joinGame(lobbyGameId)
    }
    catch (e) {
      alert(e.message)
      console.error(e)
      return
    }
    // alert(JSON.stringify(res.data))
    setLoading(false)

    if (lobbyGames) {
      //update isWaiting for the selected game
      var updatedGames = lobbyGames.map((game) => {
        if (game.id === lobbyGameId) {
          game.isWaiting = true
        }
        return game
      })
      setLobbyGames(updatedGames)
    }


  }

  useEffect(() => {

    if (user)
      getLobby()
  }, []);

  useEffect(() => {


    const timer = setInterval(() => getLobby(), 180000)


    // Cleanup function
    return () => {
      clearInterval(timer);
    }
  }, []);




  const renderSmallItem = ({ item }) => {

    return (

      <FontAwesomeIcon icon={faUserSecret} style={{ marginRight: 6, marginLeft: 6, color: "rgba(255,255,255,0.6)", fontSize: 20 }} />


    )
  }
  const renderSmallItemOld = ({ item }) => {

    return (
      <Col xs={2} md={1} lg={1} className="p-2 text-center justify-content-center align-items-center" >
        <FontAwesomeIcon icon={faUserSecret} style={{ alignSelf: "center", color: "rgba(255,255,255,0.6)", fontSize: 20 }} />
      </Col>

    )
  }


  if (loading)
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );


  if (lobbyGames.length == 0)
    return (
      <Container className="mb-5 text-center">
        {/* <Text style={{ color: "#fff", fontSize: 12, fontWeight: 300, letterSpacing: 2, fontStyle: "italic" }}>No games available</Text> */}
      </Container>
    );


  if (lobbyGames.length > NB_SHOWN) {
    var smallLobbyGames = lobbyGames.slice(0, NB_SHOWN)
  }
  else {
    var smallLobbyGames = lobbyGames
  }
  return (
    <Container className="mb-5">
      <LobbyGames lobbyGames={lobbyGames} joinGame={joinGame} show={showLobbyGame} setShow={setShowLobbyGame} />
      <Row className="justify-content-center">
        <Col xs={12} md={12} lg={7} className="text-center ">
          <TouchableOpacity
          onPress={() => setShowLobbyGame(true)}
          style={{flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderColor: "#ffca2c",
          borderWidth: 0.5,
          padding: 12,
          borderRadius: 8,}}
          >
          <View style={{marginTop: 3, marginRight: 12}}>
          <FontAwesomeIcon icon={faBell} style={{  color: "#ffca2c", fontSize: 20 }}  />
          </View>

          <Text style={{ color: "#fff", fontSize: 12, fontWeight: 300, letterSpacing: 1, }}><Text style={{ color: "#fff", fontSize: 14, fontWeight: 800, letterSpacing: 1 }}>{lobbyGames.length}</Text> people waiting for an opponent </Text>
          {/* <Text style={{ marginLeft: 12, color: "#fff", fontSize: 12, fontWeight: 300, letterSpacing: 1, }}>
            See <FontAwesomeIcon icon={faEye} style={{fontSize: 12}}/>
          </Text> */}
        <View style={{marginTop: 3, marginLeft: 12}}>
          <FontAwesomeIcon icon={faUpRightFromSquare} style={{  color: "#ffca2c", fontSize: 20 }}  />
          </View>
          </TouchableOpacity>

        </Col>
      </Row>

    </Container>




  );


}

const styles = StyleSheet.create({

  titleText: {
    color: "#555",
    fontSize: 15,
    marginRight: 6,
    marginLeft: 6,
  },

});
