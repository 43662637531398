import React, { Component } from "react";
import strings from "../config/strings";
import Image from "../components/Image"
import Utils from "../utils/utils";
import {SECONDARY_COLOR} from "../config/styles";
import { Chessboard } from "react-chessboard";
import { useState, useEffect, useMemo, useRef } from 'react';
import { SERVER_API_URL } from '../config/Constants'
import Loading from '../components/Loading'
import ImageBackground from '../components/ImageBackground'
import { HEADER_HEIGHT } from '../config/Constants'

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

const PIECES_TAKEN_HEIGHT = 40
export default function AntiBot(props) {

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });


  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onDrop = (sourceSquare, targetSquare) => {

    if (sourceSquare === 'd1' && targetSquare === 'd8')
      props.onSuccess()
    else
      alert("Take the black queen with your queen")
  }

  


    var smallWidth = windowDimensions.width <= 480
    var prct = smallWidth ? 0.92 : 0.8
    var minSize = Math.min(windowDimensions.width, windowDimensions.height - HEADER_HEIGHT - (PIECES_TAKEN_HEIGHT * 2)) * prct


    return (

      <View style={{ alignItems: "center"}}>
      
      


      <Text style={{color:'#fff', marginBottom:10}}>Verify that you are not a robot by taking the black queen with the white queen</Text>
      
      <View
          style={{
            //width: "100%",
            width: minSize + 'px'
          }}>


      <Chessboard
              style={{
                width: minSize + 'px',
                height: minSize + 'px'
              }}
              id="BasicBoard"
              position={'rnbqkbnr/ppp1pppp/8/8/8/8/PPP1PPPP/RNBQKBNR w KQkq - 0 1'}
              onPieceDrop={onDrop}
              isDraggablePiece={(piece) => { return true }}
             
              customBoardStyle={{
                boxShadow: "0 5px 25px rgba(0, 0, 0, 0.5)",
                borderRadius: "6px",
              }}
              customDropSquareStyle={{ boxShadow: "inset 0 0 1px 4px rgba(255, 255, 0, 0.5)" }}
              //customLightSquareStyle={{ backgroundColor: "rgba(255, 255, 0, 0.4)" }}
              //customDarkSquareStyle={{ backgroundColor: "rgba(110, 15, 18, 0.6)" }}
              customLightSquareStyle={{ backgroundColor: "#D1A038" }}
              customDarkSquareStyle={{ backgroundColor: "#A1291F" }}
              boardOrientation={'white'}
         

            />
      </View>
      </View>
    );
  
}

const styles = StyleSheet.create({
  
  titleText: {
    color: "#555",
    fontSize: 15,
    marginRight: 6,
    marginLeft: 6,
  },

});
