//export const SERVER_API_URL = "https://app.rowcounterapp.com"
//export const SERVER_API_URL = "http://127.0.0.1:3000"
//export const WEBSOCKET_URL = "ws://127.0.0.1:3000"
export const SERVER_API_URL = "https://api.sumochess.org"
export const WEBSOCKET_URL = "wss://api.sumochess.org"

export const APP_NAME = "sumochess"

export const HEADER_HEIGHT = 56 //72
/*
export const STUPID_ADJ = [
    "Silly",
    "Dumb",
    "Stupid",
    "Crazy",
    "Lazy",
    "Weird",
    "Boring",
    "Ugly",
    "Hilarious",
     "Absurd",
     "Goofy",
     "Ridiculous",
     "Whacky",
     "Silly",
     "Grotesque",
     "Drooling",
     "Balderdash",
     "Dopey",
     "Fluffy",
     "Gimcrack",
     "Ludicrous",
     "Oafish",
     "Sheepish",
     "Unsavvy",
     "Wonky",
     "Flibbertigibbet",
     "Numbskull",
     "Zany",
]*/

export const STUPID_ADJ = [
    "Farty",
    "Dumby",
    "Smelly",
    "Jerky",
    "Moron",
    "Weirdy",
    "Boring",
    "Ugly",
    "Ass",
    "Blunder",
    "Blunder",
    "Fool",
    "Foolish",
    "Chump",
    "Idiot",
    "Cretin",
    "Walrus"
]

export const JAP_FIRST_NAMES = ["Hiroshi", "Yuki", "Haruki", "Akira", "Kenji", "Sora", "Taro", "Hikaru", "Ryo", "Daichi", "Satoshi", "Shotaro", "Masaki", "Ryota", "Yoshio", "Naoki", "Junichi", "Kaito", "Makoto", "Yuichi"]

export const JAP_LAST_NAMES = ["zuki", "ashi", "aka", "abe", "ito", "mamoto", "kamura", "bayashi", "kato", "shida", "mada", "saki", "maguchi", "jita", "endo", "oki", "mori", "kuda", "aito", "miura"]
