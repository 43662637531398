import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faHourglassHalf, faChess, faArrowRight, faUserSecret, faEye, } from '@fortawesome/free-solid-svg-icons'
import Utils from '../utils/utils'
import moment from 'moment';
import ListGroup from 'react-bootstrap/ListGroup';
import useUser from '../hooks/useUser';

import {
    View,
    Text,
    TouchableOpacity,
    ScrollView
  } from 'react-native';


export default function LobbyGames(props) {
  const [show, setShow] = useState(props.show);
  const [lobbyGames, setLobbyGames] = useState(props.lobbyGames);
  const { user, setUser } = useUser();

  const handleClose = () => props.setShow(false);
  const handleShow = () => setShow(true);

  const renderItem = ( item ) => {
    console.log(item)
    if (!item || !item.user)
        return null

    return (
      <ListGroup.Item
        className="d-flex pt-2 pb-3 bg-transparent"
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
       >
        <View  style={{
          flexDirection: 'row',

        }}>
        <View style={{
          textWrap: "wrap",
          width: "85%"
        }}>
          <Text style={{
            color: "rgba(255,255,255,0.8)",
            fontSize: 12,
            fontWeight: 300,
            letterSpacing: 1,
            marginBottom: 8,
            fontStyle: "italic",


          }}><FontAwesomeIcon icon={faChess} style={{ color: "#FFD145", fontSize: 14 }} />  with <Text style={{
            fontWeight: 600,
            color: "#FFD145",
            letterSpacing: 2,
            fontStyle: "normal"
          }}>{item.user.name}</Text></Text>
          <Text style={{ color: "#fff", fontWeight: 600, fontSize: 10, opacity: 0.8 }}>
            {moment(item.created_at).fromNow()}
            <FontAwesomeIcon style={{ marginRight: 6, marginLeft: 8, fontSize: 10 }} icon={faStar} />{item.user.elo}      <FontAwesomeIcon style={{ marginRight: 6, fontSize: 10 }} icon={faHourglassHalf} /> {Utils.getHumanTime(item.time)}</Text>

        </View>
        <TouchableOpacity

          onPress={() => {
            props.joinGame(item.id)
          }}
        >
        
         <FontAwesomeIcon icon={faArrowRight} style={{ color: "#FFD145", fontSize: 18 }} />
        </TouchableOpacity>
        </View>
        {item.isWaiting && <Text style={{color:"#fff"}}>Game will start if other user accepts it</Text>}
        
      </ListGroup.Item>
    )
  }


  return (
      <Offcanvas show={props.show} onHide={handleClose} placement="start" className="bg-dark" >
        <Offcanvas.Header className="bg-dark">
          <Offcanvas.Title style={{color:"#FFD145", letterSpacing: 1, opacity: 0.9}}><FontAwesomeIcon icon={faUserSecret} style={{paddingRight: 8}} /> New Games Available with real humans</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body >
            <ScrollView>
            {
                lobbyGames.map((game) => {
                    console.log(game)
                    return renderItem(game)
                }
                )
            }
            </ScrollView>

            <View style={{marginTop: 18, marginLeft: 6, flexDirection: "row"}}>
            <Button variant="light" onClick={handleClose} size="sm" >
                Close
            </Button>

            </View>
        </Offcanvas.Body>
      </Offcanvas>
  );
}
