import Utils from '../utils/utils'
import GameService from "../services/GameService";
import { Chess } from 'chess.js';
//import SoundFart from '../sounds/fart.mp3';

export default class GameUtils {

	constructor(game, isWhite, isBotGame, myTurn, soundDisabled = false) {
		//console.log("GameUtils constructor isWhite", isWhite, "isBotGame", isBotGame, "myTurn", myTurn)
		this.game = game;
		this.isWhite = isWhite;
		this.isBotGame = isBotGame;
		this.myTurn = myTurn;
		//this.audioFart = new Audio(SoundFart)
		this.soundDisabled = soundDisabled
		this.debugHistory = ''
	}



	static findPartner(game) {
		return GameService.findPartner(game, (event) => {
			//setProgress(Math.round((100 * event.loaded) / event.total));
		  })

	}

	static acceptGame(lobbyGameId, userId) {
		return GameService.acceptGame(lobbyGameId, userId, (event) => {
			//setProgress(Math.round((100 * event.loaded) / event.total));
		  })

	}

	static askPlayerInBotGame(lobbyGameId, userId) {
		return GameService.askPlayerInBotGame(lobbyGameId, userId, (event) => {
			//setProgress(Math.round((100 * event.loaded) / event.total));
		  })

	}

	static getGame(gameId) {
		return GameService.getGame(gameId, (event) => {
			//setProgress(Math.round((100 * event.loaded) / event.total));
		  })

	}


	static makeMove(gameId, fen, lastMove, isWinningMove) {
		return GameService.makeMove(gameId, fen, lastMove, isWinningMove, (event) => {
			//setProgress(Math.round((100 * event.loaded) / event.total));
		  })

	}

	static createBotGame(game) {
		return GameService.createBotGame(game, (event) => {
			//setProgress(Math.round((100 * event.loaded) / event.total));
		  })

	}

	static gameTimeout(gameId) {
		return GameService.gameTimeout(gameId, (event) => {
			//setProgress(Math.round((100 * event.loaded) / event.total));
		  })

	}

	static gameCancelled(gameId) {
		return GameService.gameCancelled(gameId, (event) => {
			//setProgress(Math.round((100 * event.loaded) / event.total));
		  })

	}

	static confirmMove(gameId) {
		return GameService.confirmMove(gameId, (event) => {
			//setProgress(Math.round((100 * event.loaded) / event.total));
		  })

	}
	

	static checkMoveConfirmed(gameId) {
		return GameService.checkMoveConfirmed(gameId, (event) => {
			//setProgress(Math.round((100 * event.loaded) / event.total));
		  })

	}

	static leftGame(gameId) {
		return GameService.leftGame(gameId, (event) => {
			//setProgress(Math.round((100 * event.loaded) / event.total));
		  })

	}

	static postChat(gameId, message) {
		return GameService.postChat(gameId, message, (event) => {
			//setProgress(Math.round((100 * event.loaded) / event.total));
		  })

	}



	static countTotalPieces(fen) {
		const fenParts = fen.split(' ')[0];
		let total = 0;
		for(let char of fenParts) {
			if(isNaN(parseInt(char)) && char!=='/') {
				total++;
			}
		}
		return total;
	}

	constructHistory = (moves) => {

		var tmpFen = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
		var history = []
		history.push(tmpFen)

		if (!moves)
		  return history

		var moveList = moves.split(';')
		for (var i = 0; i < moveList.length; i++) {
		  if (!moveList[i])
			continue
		  var moveArray = moveList[i].split(',')
		  var from = moveArray[0]
		  var to = moveArray[1]

		  var tmpGame = new Chess(tmpFen)
		  if (moveArray.length > 2) {
			var displacedPiecePosition = moveArray[2]
			var piece = tmpGame.get(to)
			tmpGame.put(piece, displacedPiecePosition)
		  }
		  console.log("constructHistory", 'from', from, 'to', to)
		  var piece = tmpGame.get(from)
		  // check if castling
		  if (piece.type === 'k') {
			var rook = {type : 'r', color: piece.color}
			if (from === 'e1' && to === 'g1') {
				tmpGame.put(rook, 'f1')
				tmpGame.remove('h1')
			}
			else if (from === 'e1' && to === 'c1') {
				tmpGame.put(rook, 'd1')
				tmpGame.remove('a1')
			}
			else if (from === 'e8' && to === 'g8') {

				tmpGame.put(rook, 'f8')
				tmpGame.remove('h8')
			}
			else if (from === 'e8' && to === 'c8') {
				tmpGame.put(rook, 'd8')
				tmpGame.remove('a8')
			}
		  }
		  if (piece.type === 'p' && ((piece.color === 'w' && to[1] === '8') || (piece.color === 'b' && to[1] === '1') ))
			piece.type = 'q'
		  tmpGame.remove(from)
		  tmpGame.remove(to)
		  tmpGame.put(piece, to)

		  tmpFen = tmpGame.fen()

		var arrayMoveOut = []
		const lastMove = [moveArray[0], moveArray[1], 'blue']
		arrayMoveOut.push(lastMove)
		if (moveArray.length > 2) {
		const pushedPiece = [moveArray[1], moveArray[2], 'red']
		arrayMoveOut.push(pushedPiece)
		}

		  history.push({fen: tmpFen, moves: arrayMoveOut})
		}
		return history

	}

	
	getPiecesTaken(fen) {
		// Define pieces type
		const pieces = ['P', 'N', 'B', 'R', 'Q', 'K', 'p', 'n', 'b', 'r', 'q', 'k'];

		// Define initial pieces count (starting chess position)
		const initialPiecesCount = {
			'P': 8, 'p': 8,
			'N': 2, 'n': 2,
			'B': 2, 'b': 2,
			'R': 2, 'r': 2,
			'Q': 1, 'q': 1,
			'K': 1, 'k': 1,
		};

		// Split fen by space
		const fenBoard = fen.split(" ")[0].split('');

		// Count pieces in fen
		const fenPiecesCount = pieces.reduce((acc, curr) => {
			acc[curr] = fenBoard.filter(char => char === curr).length;
			return acc;
		}, {});

		// Calculate pieces difference
		const capturedPieces = {};
		pieces.forEach(piece => {
			capturedPieces[piece] = initialPiecesCount[piece] - fenPiecesCount[piece];
		});

		return capturedPieces;
	}

	nextStep(start, end, isKnight = false, isTest = false) {
		const columns = {'a': 1, 'b': 2, 'c': 3, 'd': 4, 'e': 5, 'f': 6, 'g': 7, 'h': 8};

		const sx = columns[start[0]];
		const sy = parseInt(start[1]);

		const ex = columns[end[0]];
		const ey = parseInt(end[1]);

		// Calculating direction of movement.
		let dx = ex - sx;
		let dy = ey - sy;

		if (sx === ex) {
			dy = ey < sy ? -1 : 1; // Moving up or down
		} else if (sy === ey) {
			dx = ex < sx ? -1 : 1; // Moving left or right
		} else {// if (Math.abs(dx) === Math.abs(dy)) {
			dx = ex < sx ? -1 : 1; // Moving diagonally left or right
			dy = ey < sy ? -1 : 1; // Moving diagonally up or down
		}
	//alert(dx + ' ' + dy + ' ' + end[0] + ' ' + end[1]
		if (isKnight)
		dy = 0
		if (dx < 0 && end[0] === 'a' || dx > 0 && end[0] === 'h'
			|| dy < 0 && end[1] === '1' || dy > 0 && end[1] === '8') {
			  this.saveToDebug('pushed outside of board')

			 /* if (!isTest) {
				try {
				  if (this.audioFart && !this.soundDisabled)
				  this.audioFart.play()
				}
				catch (e) {
				}
			  }*/
		  return null
		}



		// Calculating the next move.
		const columnLetter = Object.keys(columns).find(key => columns[key] === ex + dx);
		const nextMove = columnLetter + (ey + dy);

		return nextMove;
	}

	 saveToDebug = (txt, showTime = false) => {
		if (showTime) {
		  var date = new Date();
		  var hour = date.getHours();
		  var minutes = date.getMinutes();
		  var seconds = date.getSeconds();
		  txt = hour + ':' + minutes + ':' + seconds + ' ' + txt;
		}
		this.debugHistory = this.debugHistory + txt + '\n'
		console.log(txt)
	  }

	  checkIfHasMoves = (game, lastMoveAsString, colorToCheck, disableLogs = true) => {
		var savedFen = game.fen()


		//console.log("checkIfHasMoves")
		//var lastMove = lastMoveAsString.split(',')
		var moves = game.moves({ verbose: true })

		if (!disableLogs)
			this.saveToDebug('checking if has possible moves ' + moves.length + ' moves found ' + game.fen())
		for (var i = 0; i < moves.length; i++) {
		  var move = moves[i]

		  var tmpGame = new Chess(savedFen)

		  var hasValidMove = this.makeAMove(move, colorToCheck, true, tmpGame, lastMoveAsString)
		  if (hasValidMove) {
		 //  alert("has move " + JSON.stringify(move))
			game = new Chess(savedFen)
			return move
		  }
		}
		//on regarde si y a pas moyen de bouger une piece adverse pour se mettre entre le roi et la piece qui attaque
		var tmpGame = new Chess(game.fen())
		var moves = game.movesIncludeCheck({ verbose: true })

		if (!disableLogs) {
			this.saveToDebug('no standards moves found, check non standards ' + moves.length + ' moves found ' + game.fen())
			this.saveToDebug('non standards moves ' + JSON.stringify(moves))
		}

		for (var i = 0; i < moves.length; i++) {
		  var move = moves[i]

		  var tmpGame = new Chess(savedFen)

		  var hasValidMove = this.makeAMove(move, colorToCheck, true, tmpGame, lastMoveAsString, true)
		  if (hasValidMove) {
			if (!disableLogs)
				this.saveToDebug("has move " + JSON.stringify(move))
			game = new Chess(savedFen)
			return move
		  }
		}

	   // alert(JSON.stringify( tmpGame.movesIncludeCheck({ verbose: true })))
	   // return


		return false

	  }

	getPiecePosition = (game, piece) => {
		for (let row = 8; row >= 1; row--) {
		  // Loop over each column (a through h)
		  for (let col = 'a'.charCodeAt(0); col <= 'h'.charCodeAt(0); col++) {
			let position = String.fromCharCode(col) + row;
			let tmpPiece = game.get(position);
			// If a piece exists and is the white king, print position
			if (tmpPiece && piece.type === tmpPiece.type && piece.color === tmpPiece.color) {
			 // console.log("White king is at " + position);
			  return position;
			}
		  }
		}
		return null
	  }

	  checkIfInCheck = (game, color, isTest) => {

		var kingPos = this.getPiecePosition(game, {type: 'k', color: color})
		//alert('checking king pos ' + kingPos)
		if (game.isAttacked(kingPos, color === 'w' ? 'b' : 'w')) {
		  if (!isTest) {
			this.saveToDebug('king in check')
			//if (isWhite && color === 'w' || !isWhite && color === 'b')
			  //alert('our king would be in check')
		  }
		  return true
		}
		return false
	  }


	makeAMove = (move, colorToCheck, isTest = false, currentGame, lastMoveAsString, showResult, allowInvalidMoves = false) => {


		//var colorToCheck = isWhite ? 'w' : 'b'

		//si on est en echec, on va voir si y a pas des sumos moves qui pourraient nous sortir de l'echec
		if (!isTest && this.checkIfInCheck(currentGame, colorToCheck, isTest)) {
		  var movesWithInvalid = currentGame.movesIncludeCheck({ verbose: true })
		  //check si mouvement permis (sans prendre en compte check)
		  var foundCurrentMove = false
		  for (var i = 0; i < movesWithInvalid.length; i++) {
			//console.log("check movement non permis", movesWithInvalid[i])
			if (movesWithInvalid[i].from === move.from && movesWithInvalid[i].to === move.to) {
			  foundCurrentMove = true
			  break
			}
		  }
		  if (!foundCurrentMove) {
			//alert('nope ' + currentGame.fen())
			return false
		  }

		  allowInvalidMoves = true
		}

		var tmpLastMove = lastMoveAsString ? lastMoveAsString.split(',') : null

		var tmpGame = new Chess(currentGame.fen())

		//const moveFrom = move.from ? move.from : move.slice(0, 2)
		//const moveTo = move.to ? move.to : move.slice(-2)


	   // alert('check last move ' + JSON.stringify(tmpLastMove) + " = > " + JSON.stringify(move))
		//si inverse de dernier move, pas valide
		if (tmpLastMove && tmpLastMove.length > 2 && tmpLastMove[1] === move.to && tmpLastMove[2] === move.from) {

		  if (!isTest) {
			this.saveToDebug('invalid move, cannot push back')
			alert("you cannot move back immediately to your previous position after having been pushed back")
		  }
		  return false
		}
		//console.log("makeAMove", 'seems like it was valid')



		//on test, mais c'est tmpGame qui est modifié, pas le vrai truc
		var result
		if (allowInvalidMoves) {
		  result = {}
		}
		else {
		  try {

			result = tmpGame.move(move);

		  }
		  catch (e) {
			if (!isTest)
			  this.saveToDebug(JSON.stringify(move) + ' ' + e.message)
	   //   alert(JSON.stringify(move) + ' ' + e.message)
			return false
		  }
		}
		//setGame(gameCopy);

		var pieceBefore = null

		var displacedPiecePosition = null

		if (!result) {
		  this.saveToDebug("move2 not valid")
		  return false
		}

		  const currentPiece = currentGame.get(move.from)
		  //check si prend une piece, sauf si roi
		  if (currentGame.get(move.to) && currentPiece.type !== 'k') {

			displacedPiecePosition = this.nextStep(move.from, move.to, currentPiece.type === 'n', isTest)
		  //alert(nextMove + ' ' + JSON.stringify( tmpGame.get(nextMove)))
			if (displacedPiecePosition && tmpGame.get(displacedPiecePosition)) {

			  if (!isTest) {
				alert('a piece is blocking behind')
				this.saveToDebug('a piece is blocking behind')
			  }
			  return false
			}

			if (displacedPiecePosition) {
			  pieceBefore = currentGame.get(move.to)

			}
		  }


		  //const newGame = recreateBoard(game)


		  //doit etre avant le move, au cas ou echec et possible de pousser une piece pour bloquer l'echec
		  if (pieceBefore) {
			if (!isTest)
			  this.saveToDebug('before putting pushed piece ' + JSON.stringify(currentGame.fen()))
			//alert('has piece before')
			//si pion et pion est maintenant au bord, on le promeut
			//if (pieceBefore.type === 'p' && (displacedPiecePosition[1] === '1' || displacedPiecePosition[1] === '8'))
			 // pieceBefore = {type: 'q', color: pieceBefore.color}
			 currentGame.put(pieceBefore, displacedPiecePosition)

			 if (!isTest)
			  this.saveToDebug('after putting pushed piece ' + JSON.stringify(currentGame.fen()))
		  }

		  /*if (allowInvalidMoves) {
			var p = currentGame.remove(move.from)
			currentGame.put(p, move.to)
		  }
		  else*/
		  try {
			currentGame.move(move);
		  }
		  catch (e) {
			if (!isTest)
			  this.saveToDebug('second move not valid: ' + JSON.stringify(move) + ' ' + e.message + ' ' + currentGame.fen())

			  if (pieceBefore) {
				currentGame.remove(displacedPiecePosition)
			  }
			  return false
		  }




		  //check si notre propre roi est en echec
		  if (isTest)
			{
			  colorToCheck = colorToCheck === 'w' ? 'b' : 'w'
			}

		  if (this.checkIfInCheck(currentGame, colorToCheck, isTest)) {

			if (!isTest)
				alert('king would be in check ' + currentGame.fen())
			//setGame(new Chess(fenBeforeMyMoveRef.current))
			return false
		  }
	//alert('king would not be in check')
		  if (isTest)
			return true

		  var tmpLastMove = move.from + ',' + move.to + (displacedPiecePosition ? ',' + displacedPiecePosition : '')
		  var hasMovesLeft = this.checkIfHasMoves(currentGame, tmpLastMove, colorToCheck)


		  //on met bien la couleur de l'adversaire pour le prochain coup

		  var currentFen = currentGame.fen()
		  if (this.isWhite) {
			if (this.myTurn) {
			//  alert('my turn replacing w with b' + currentFen)
			  currentFen = currentFen.replace(/ w /, ' b ')
			}
			else {
			// alert('not my turn replacing b with w' + currentFen)
			  currentFen = currentFen.replace(/ b /, ' w ')
			}
		  }
		  //si je suis black
		  else {
			if (this.myTurn)
			  currentFen = currentFen.replace(/ b /, ' w ')
			else
			  currentFen = currentFen.replace(/ w /, ' b ')
		  }


		  if (!hasMovesLeft && this.myTurn)
			showResult(true)
		  else if (!hasMovesLeft && !this.myTurn)
			showResult(false)


		return {currentFen, lastMoveAsString: tmpLastMove, hasMovesLeft: hasMovesLeft ? true : false }

	  }



}
