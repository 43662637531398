import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const token = localStorage.getItem('token');

    if (!token || token === 'undefined' || token === 'null') {
      return null
    }
   // const userToken = tokenString;

    
    return token
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('token', userToken);

    //alert('token set ' + userToken)
    setToken(userToken);
  };
  return {
    setToken: saveToken,
    token
  }
}