import React, { useState, useEffect } from 'react';
import { faMessage, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const chatBubbleStyle = {
    padding: '10px 20px',
    backgroundColor: 'rgba(128, 255, 128, 0.5)', // Blue background
    color: 'white', // White text
    borderRadius: '25px',
    maxWidth: '200px',
    minHeight: '50px',
    position: 'fixed',
    top: '20px',
    right: '20px',
    zIndex: 1000, // Ensures it overlays other elements
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    transition: 'opacity 0.5s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

const ChatBubble = ({ message }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000);

    // Cleanup timer if component unmounts before 5 seconds
    return () => clearTimeout(timer);
  }, []);

  if (!visible) return null;

  return (
    <div style={chatBubbleStyle}>
    <FontAwesomeIcon icon={faMessage} style={{fontSize: 14, color: "#fff", marginRight:8}} /> {message}
    </div>
  );
};

export default ChatBubble;
