import React, { Component } from "react";
import ReactNative from "react-native";
import Image from "../components/Image"

import {
  ScrollView,
  View,
  TextInput,
  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';


export default class CheckBox extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      
            <TouchableOpacity
            style={[styles.btn, this.props.style, { borderWidth: this.props.checked ? 0 : 1}]}
            onPress={() => this.props.onPress()}
          >

          {this.props.checked && <Image urlOnServer={'/imgs/check.png'} style={{borderWidth: 1, borderColor: '#2ba5f7', height:15, width:15, tintColor:'#2ba5f7'}} resizeMode={'contain'}/> }
           
          </TouchableOpacity>
          
      )
  }
}

const styles = StyleSheet.create({
btn: {
    height:15,
    width:15,
    borderWidth:1,
    borderColor:'#aaa',
  }
});
