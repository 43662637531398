import React from 'react';
import useToken from '../hooks/useToken';
import useUser from '../hooks/useUser';
import { useState, useEffect, useMemo, useRef } from 'react';
import { SERVER_API_URL } from '../config/Constants'
import Loading from '../components/Loading'
import ImageBackground from '../components/ImageBackground'
import { HEADER_HEIGHT } from '../config/Constants'
import { registerAnonymousUser } from '../containers/Login'
import GameUtils from '../utils/gameUtils'
import { useNavigate } from "react-router-dom";
//import { useCable } from '../contexts/CableProvider';
//import { useChannel } from '@aersoftware/react-use-action-cable';
import SoundDing from '../sounds/ding.mp3';
import Image from "../components/Image"
import Lobby from '../components/Lobby';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb} from '@fortawesome/free-solid-svg-icons'
import HelpModal from '../components/HelpModal';
import AccountModal from '../components/AccountModal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LobbyChannel from '../channels/LobbyChannel'
import cable from '../channels/cable'
import Utils from '../utils/utils';
import Countdown from '../components/Countdown';

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';



export default function Main() {

  // const actionCable = useCable();
  // const { subscribe, unsubscribe, send } = useChannel(actionCable)

  const navigate = useNavigate();
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [waitForGame, setWaitForGame] = useState(false);
  const [selectedTime, setSelectedTime] = useState(-1);
  const [availableGames, setAvailableGames] = useState([]);
  const [showHelp, setShowHelp] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [waitingForUserToAccept, setWaitingForUserToAccept] = useState(false);

  const audioDing = useMemo(() => new Audio(SoundDing), []);
  const hasDinged = useRef(false)
  const lastPingAt = useRef(0);
  const isConnected = useRef(false);
  const lastInteractionAt = useRef(0);
  const needRefresh = useRef(false);
  const pingingTimer = useRef(null);
  const waitForGameTime = useRef(-1);
  const waitForGameRef = useRef(null);
  const availableGamesRef = useRef([]);
  
  const handleReceived = (data) => {
   //  alert(JSON.stringify(data) + '>>' + JSON.stringify(user))
    // alert('handleReceived' + JSON.stringify(data) + '>>' + user.id)
    console.log(data) // response received from rails

    var user = localStorage.getItem('user') || null;
    if (!user || user === 'undefined' || user === 'null') {
      //  alert(JSON.stringify(user))
      return null
    }
    user = JSON.parse(localStorage.getItem('user'));


    if (!data || !data.action || !user)
      return

    if (data.action == 'game_starts') {
      // alert('nav')
      removeFromAvailableGamesWithUsers(data)
      if (data.player1_id == user.id || data.player2_id == user.id) {
        try {
          if (audioDing)
            audioDing.play()
        }
        catch (e) {
        }
        navigate("/game/" + data.game_id);
      }
      else {
        //remove from availableGames
       // removeFromAvailableGames({unique_id: data.unique_id})
      }

    }
    if (data.action == 'game_new_bot') {
     // alert(JSON.stringify(availableGamesRef.current))
      for (var i = 0; availableGamesRef.current && i < availableGamesRef.current.length; i++) {
        if (availableGamesRef.current[i].user_id == data.user_id) {
          availableGamesRef.current[i].is_playing_a_bot_now = true
          break
        }
      }
      setAvailableGames([...availableGamesRef.current])
    }
    // alert(user.id + '  ' + data.user_id)
    // alert(JSON.stringify(data))
    if (data.action == 'game_new' && (data.user_id != user.id)) {
      //check if not already in availableGames
      var alreadyInAvailableGames = availableGamesRef.current.filter((game) => game.unique_id == data.unique_id)
      if (alreadyInAvailableGames.length === 0) {
//alert(data.time + '   ' +  waitForGameType.current + '   ' + waitForGame)
        if (waitForGameRef.current && waitForGameTime.current != data.time && data.time != -1)
          return
        data.addedAt = Date.now()
        availableGamesRef.current.push(data)
        setAvailableGames(availableGamesRef.current)
        //alert('added')
      }

      if (!hasDinged.current) {
        try {
          hasDinged.current = true
          if (audioDing)
            audioDing.play()
        }
        catch (e) {
        }
      }
    }

  }

  useEffect(() => {
    function handleMouseMove(event) {
      //console.log(`Mouse moved: X:${event.clientX}, Y:${event.clientY}`);
      lastInteractionAt.current = Date.now()
    }

    window.addEventListener('mousemove', handleMouseMove);

    // Be sure to remove event listener when the component unmounts
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount



  /*useEffect(() => {
    subscribe({
      channel: 'LobbyChannel',
    }, {
      received: (data) => handleReceived(data),
      // Custom callbacks can be added for 'initialized', 'connected', and 'disconnected'
    })
    return () => {
      unsubscribe()
    }
  }, [])*/

  const checkPing = () => {

    console.log("checking ping", lastPingAt.current, Date.now())
    //if haven't had a ping since 10 s, we refresh the page
    if (lastPingAt.current < Date.now() - (10 * 1000)) {
      console.log('no ping, reloading')


      var obj = null
      var str = localStorage.getItem('reloadInfoMain')
      if (str)
        obj = JSON.parse(str)

      //resetting to 0
      if (obj && obj.lastReload && obj.lastReload < Date.now() - (15 * 60 * 1000)
        && lastInteractionAt.current > Date.now() - (10 * 60 * 1000)) {

        obj.lastReload = Date.now()
        obj.nbReload = 0
        localStorage.setItem('reloadInfoMain', JSON.stringify(obj))
        window.location.reload()
      }
      ////prevents flooding the server
      else if (obj && obj.nbReload && obj.nbReload > 5) {
        console.log('too many reloads')
      }
      else if (lastInteractionAt.current > Date.now() - (10 * 60 * 1000)) {
        if (!obj)
          obj = {}
        if (!obj.nbReload)
          obj.nbReload = 0
        obj.nbReload++
        obj.lastReload = Date.now()
        localStorage.setItem('reloadInfoMain', JSON.stringify(obj))
        window.location.reload()
      }


    }
  }




  useEffect(() => {


    const timer = setInterval(() => checkPing(), 10000)


    // Cleanup function
    return () => {
      clearInterval(timer);
    }
  }, []);

  /*

  useEffect(() => {

    const subscription = consumer.subscriptions.create({
      channel: 'LobbyChannel',

    }, {
      connected: () => {
        if (needRefresh.current) {
          window.location.reload();
          //checkIfGameHasStarted()
          needRefresh.current = false
        }
        isConnected.current = true
        lastPingAt.current = Date.now()

        if (pingingTimer.current)
          clearInterval(pingingTimer.current)
        pingingTimer.current = setInterval(() => subscription.perform('message', { text: 'pinging' }), 10000)

      },
      disconnected: () => {
        if (pingingTimer.current)
          clearInterval(pingingTimer.current)
         console.log('disconnected')
         isConnected.current = false
         needRefresh.current = true

        },
      received: data => handleReceived(data),
      ping: () => { lastPingAt.current = Date.now() },
    })

    // Cleanup function
    return () => {
    //  if (isConnected.current) {
      //  isConnected.current = false
        consumer.subscriptions.remove(subscription);
     // }
    }

  }, []); // Notice the empty array here. This tells React to run the effect only once, thus mimicking componentDidMount.

*/

useEffect(() => {

  const channel = new LobbyChannel({ })


// Subscribe to the server channel via the client.
cable.subscribe(channel) // return channel itself for chaining



// Handle incoming messages
let unbindMessage = channel.on('message', msg =>  {
// alert(JSON.stringify(msg))
handleReceived(msg)
console.log(`${msg.name}: ${msg.text}`)}
)


let unbindPing = cable.on('keepalive', msg =>  {
  lastPingAt.current = Date.now() })


  // Cleanup function
  return () => {
    // Unsubscribe from the channel (results in a 'close' event)
    channel.disconnect()
    unbindMessage()
    unbindPing()
  }

}, []);


  const checkIfGameHasStarted = async () => {

    try {
      var res = await GameUtils.getHistory()
      if (res && res.data) {

      }
    }
    catch (e) {
    //  alert(e.message)
      console.error(e)

      return
    }
  }


  const startGame = async (withBot = false) => {

    if (!withBot) {
      try {
        var res = await GameUtils.findPartner({ type: 0, time: selectedTime })
        setLoading(false)
        waitForGameRef.current = true
        setWaitForGame(true)
        waitForGameTime.current = selectedTime
      }
      catch (e) {
        alert(e.message)
        setLoading(false)
        waitForGameRef.current = false
        setWaitForGame(false)
      }
    }
    else {
      try {
        var res = await GameUtils.createBotGame({ type: 0, time: -1 })
        // alert(JSON.stringify(res))

        setLoading(false)

       // if (waitForGame)
        //  window.open("/game/" + res.data.id + '?isBotGame=true' + (waitForGame ? '&waitingForGameWithTime=' + waitForGameTime.current : ''), '_blank');
        //else
          navigate("/game/" + res.data.id + '?isBotGame=true' + (waitForGame ? '&waitingForGameWithTime=' + waitForGameTime.current : ''));
      }
      catch (e) {
        alert(e.message)
        setLoading(false)
      }
    }

  }

  const loginIfNeededAndStartGame = async (user, withBot = false) => {
    setLoading(true)
    if (!token) {
      var data = await registerAnonymousUser(user)

      if (!data || !data.auth_token) {
        alert('An error occured')
        return
      }
      setToken(data.auth_token);
      setUser(data.user)

      ///alert('setting user ' +JSON.stringify(user) + '>>' + JSON.stringify( localStorage.getItem('user')))
      await startGame(withBot)

      // alert(JSON.stringify(res))
    }
    else {

      await startGame(withBot)

      // alert(JSON.stringify(res))
    }
  }

  const removeFromAvailableGames = (availableGame) => {
    if (!availableGamesRef.current)
      return
    //remove from availableGames
    var newAvailableGames = availableGamesRef.current.filter((game) => game.unique_id != availableGame.unique_id)
    setAvailableGames(newAvailableGames)
  }

  const removeFromAvailableGamesWithUsers = (gameStarting) => {
    if (!availableGamesRef.current)
      return
    //remove from availableGames
    var newAvailableGames = availableGamesRef.current.filter((game) => game.id === gameStarting.player1_id || game.id === gameStarting.player2_id)
    setAvailableGames(newAvailableGames)
  }
  /*
    const isWaitingForGame = () => {
      for (var i = 0; i < availableGames.length; i++) {
        if (availableGames[i].user_id == user.id)
          return true
      }
    }*/

  const hasGameReadyToJoin = () => {
    for (var i = 0; i < availableGamesRef.current.length; i++) {
      if (availableGamesRef.current[i].user_id != user.id)
        return true
    }
    return false
  }


  const acceptGame = async (availableGame) => {

    setLoading(true)
    if (availableGame.is_playing_a_bot_now) {

      
      try {
        var res = await GameUtils.askPlayerInBotGame(availableGame.id, availableGame.user_id)
        setLoading(false)
        if (res.data.error && res.data.error == 'already_started') {
          alert("Another user already joined this game")
  
          removeFromAvailableGames(availableGame)
          return
        }
        setTimeout(() => {
          availableGamesRef.current = []
          setAvailableGames([])
          setWaitingForUserToAccept(false)
          setWaitForGame(false)
        
        }, 15000)
          
      }
      catch (e) {
        alert(e.message)
        setLoading(false)
      }
      setWaitingForUserToAccept(true)
      return
    }
    

    try {
      var res = await GameUtils.acceptGame(availableGame.id, availableGame.user_id)
      setLoading(false)
      if (res.data.error && res.data.error == 'already_started') {
        alert("Another user already joined this game")

        removeFromAvailableGames(availableGame)
        return
      }
    }
    catch (e) {
      alert(e.message)
      setLoading(false)
    }
    // alert('going to game ' + JSON.stringify(data))
    navigate("/game/" + res.data.id);
    // alert(JSON.stringify(res))

  }

  const renderButton = (text, onPress, variant) => {
    return (
      <Button
        className='mt-1 mb-4'
        onClick={() => {
          console.log('Start Game')
          onPress()
        }}
        variant={variant}
      >{text}</Button>
    )
  }

  const renderAvailableGames = () => {

    if (waitingForUserToAccept) {
      return (
        <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
          <Text style={{color:'#fff'}}>Waiting for other player confirmation, cancel in: </Text>
        <Countdown />
        </View>
      )
    }
    if (availableGames.length === 0)
      return null

    return (
      <View style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        width: "100%",
        padding: 24,
        marginVertical: 24,
        borderRadius: 12,
        backgroundColor: "rgba(0,0,0,0.3)"
      }} >
        <Text style={{ color: "rgba(255,255,255,0.4)", fontSize: 18, letterSpacing: 1, fontWeight: 'bold', marginBottom: 12 }}>Available games:</Text>
        <ListGroup defaultActiveKey="#link1">
          {
            //.filter((availableGame) => availableGame.dateAdded > Date.now() - (15 * 60 * 1000))
            availableGames.map((availableGame) => {
              return (
                <ListGroup.Item
                  action
                  style={{
                    backgroundColor: "#FFBA0E",
                    borderColor: "#A97B09",
                    color: "black",
                  }}
                  variant="warning"
                  onClick={() => acceptGame(availableGame)}>
                  {'Click to start playing with player (elo ' + availableGame.elo + '), Time: ' + Utils.getHumanTime(availableGame.time)}
               
                </ListGroup.Item>
              )
            })
          }
        </ListGroup>
      </View>

    )
  }

  if (loading) {
    return (<Loading />)
  }

  return (


    <Container
    >

      {/* <Text>My user id: {user ? user.id : 'not connected'}</Text> */}

      {loading && <Loading />}

      <HelpModal show={showHelp} onHide={() => setShowHelp(false)} />
      <AccountModal
          show={showAccount}
          onHide={() => setShowAccount(false)}
          onPlay={(user) => {
              console.log('Start Game')
              setShowAccount(false)
              loginIfNeededAndStartGame(user)}
      }/>
      <View id="lobbyContainer"  >
        <Lobby />
      </View>

      <View style={{ justifyContent: 'center', alignItems: "flex-end", flexDirection: "row" , marginTop: -12, marginBottom: 32}} >
        <Image source={"/imgs/pion-big.png"} style={{ marginRight: 4, width: 110, height: 220 }} />
        <Image source={"/imgs/pion-small.png"} style={{ marginLeft: 4, width: 110, height: 220 }} />
      </View>

      <View style={{ flex: 1, justifyContent: 'center'  , paddingBottom: 44}} >
        {!hasGameReadyToJoin() &&
          <View style={{ flex: 1, justifyContent: 'center', alignItems: "center", width: "100%" }} >
            <Dropdown
              className="bg-transparent"
              style={{backgroundColor: undefined, borderColor: undefined, color: "white"}}
              onSelect={
                (eventKey) => {
                  console.log('selected time ' + eventKey)
                  setSelectedTime(eventKey)

                }

              }>
              <DropdownButton
                as={ButtonGroup}
                id={`dropdown-time`}
                drop={"end"}
                style={{backgroundColor: undefined, borderColor: undefined, color: "white", zIndex:500}}
                variant={"link"}
                title={"Selected time: " + (selectedTime === -1 ? 'Any' : Utils.getHumanTime(selectedTime)) + " " }
              >
                <Dropdown.Item eventKey="-1" active={selectedTime == -1} >Any</Dropdown.Item>
                <Dropdown.Item eventKey="60" active={selectedTime == "60"} >1 min</Dropdown.Item>
                <Dropdown.Item eventKey="180" active={selectedTime == "180"}>3 min</Dropdown.Item>
                <Dropdown.Item eventKey="600" active={selectedTime == "600"}>10 min</Dropdown.Item>
                <Dropdown.Item eventKey="1800" active={selectedTime == "1800"}>30 min</Dropdown.Item>
                <Dropdown.Item eventKey="3600" active={selectedTime == "3600"}>1 h</Dropdown.Item>
                <Dropdown.Item eventKey="604800" active={selectedTime == "604800"}>One week</Dropdown.Item>
              </DropdownButton>
            </Dropdown>
          </View>
        }
        {
          !hasGameReadyToJoin() &&
          renderButton(
            waitForGame ? "Waiting for another player" : "Start Game",
            () => {
              if (!user || !user.id) {
                setShowAccount(true)
                return
              } else {
                loginIfNeededAndStartGame()
              }
            },
            "warning"
          )
        }



        {renderAvailableGames()}

        {
          renderButton(
            "Start Game vs Bot",
            () => {
              if (!user || !user.id) {
                setShowAccount(true)
                return
              } else {
                loginIfNeededAndStartGame(user, true)
              }
             },
            "dark"
          )
        }
        <Button id="helpLink" onClick={() => setShowHelp(true)}
            variant="link"><FontAwesomeIcon icon={faLightbulb} />  What is SumoChess?</Button>
      </View>

    </Container>



  );
}

