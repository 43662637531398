// src/CountdownTimer.js
import React, { useEffect, useState } from 'react';

const Countdown = (props) => {
  const [seconds, setSeconds] = useState(props.time ? props.time : 15);

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
      
      return () => clearInterval(interval);
    }
  }, [seconds]);

  return (

     <span style={{color:'#FFE492', width:'20px'}}>{seconds}</span>

  );
};

export default Countdown;
