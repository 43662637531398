import React from 'react';
import useToken from '../hooks/useToken';
import useUser from '../hooks/useUser';
import { useState, useEffect } from 'react';
import { SERVER_API_URL } from '../config/Constants'
import UserService from "../services/UserService";
import Loading from '../components/Loading'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSecret, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import AccountModal from '../components/AccountModal';
import fetchClient from "../utils/http-common";
import GameHistory from '../components/GameHistory';
import { registerAnonymousUser } from '../containers/Login'

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  //Image,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';
import { useRouteLoaderData } from 'react-router-dom';



export default function Profile() {

  const [ loading, setLoading ] = useState(false);
  const [ showLogin, setShowLogin ] = useState(false);
  const [ data, setData ] = useState('');
  const { user, setUser } = useUser();
  const [showAccount, setShowAccount] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
  const { token, setToken } = useToken();
  const picWidth = isMobile ? 90 : 180
  const picHeight = isMobile ? 180 : 360
  const getProfile = async (user) => {

    setLoading(true)

    try {
    var res = await UserService.getUser(user.id)
    }
    catch (e) {
      //alert(JSON.stringify(res))
      setShowLogin(true)
      setLoading(false)
      return
      console.log(e)
    }
    setLoading(false)
    setData(res.data)
    console.log(res.data)
  }

  useEffect(() => {
    getProfile(user)
  }, []);


  async function updateUser(user) {

    return fetchClient.put(`${SERVER_API_URL}/users/update.json`, user)

   }



  const renderUserData = (data) => {
    if (!data) data = "N/A"
    if (data.length > 16 && isMobile) data = data.substring(0, 16) + "..."
    return (
      <Text style={{ fontSize: 14, color: "#FFD145", fontWeight: 600, letterSpacing: 2, paddingVertical: 12, textAlign: "left", }}>{data}</Text>
    )
  }

  const renderOneData = (name, data) => {
    return (
      <Row>
      <Col xs={12} md={12}>
      <Text style={{ fontSize: 14, color: "white", paddingVertical: 6,textAlign: "left"}}>{name}: {renderUserData(data)}</Text>
      </Col>
      </Row>
    )
  }


  if (loading) {
    return <Loading />
  }

  if (showLogin) {
    return (<AccountModal
      show={true}
      onHide={() => setShowLogin(false)}
      onPlay={async (user) => {
        setLoading(true)
        var data = await registerAnonymousUser(user)
        setLoading(false)
        if (!data || !data.auth_token) {
          alert('An error occured')
          return
        }
        setShowLogin(false)
        setToken(data.auth_token);
        setUser(data.user)
        getProfile(data.user)
  }
  }/>)
  }


  return (
    <Container style={{
      //flex: 1,
      // flexDirection: isMobile ? "column" : 'row',
       //justifyContent: 'center',
       //alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.2)',
      padding: 24,
      marginTop: 20,
      borderRadius: 12,

   }}>
    {
      user &&
      <AccountModal
      show={showAccount}
      onHide={() => setShowAccount(false)}
      email={user && user.email && !user.email.includes('sumochess.org') ? user.email : undefined}
      username={user.name}
      onSave={async (user) => {
          console.log('Start Game')

          var res = await updateUser(user)
          //alert(JSON.stringify(res.data))
          if (res.data && res.data.user) {
            setUser(res.data.user)
          }
          setShowAccount(false)
          // save new values
        }
  }/>
    }

    <Row className='justify-content-center'>
      <Col xs={8} md={4}>
        <Image
        src={"/imgs/bot_small_tr.png"} fluid
        className=''
        //style={{width: picWidth, height: picHeight}}
         />
      </Col>
      <Col xs={12} md={8}>
      <Row>
      <Col xs={12} md={12}>
        <Text style={{ fontSize: 14, color: "white", paddingVertical: 6,textAlign: "left"}}>name: {renderUserData(data.name)}</Text>
        <Button
          style={{marginLeft: 8}}
          variant="dark"
          size="sm"
          onClick={() => setShowAccount(true)}>
          <FontAwesomeIcon icon={faPenToSquare} />
      </Button>
      </Col>
      </Row>
      {[ "elo",  "win_as_white", "win_as_black", "lost_as_white", "lost_as_black"].map((name) => {
  return renderOneData(name, data[name])
}
)
}
        </Col>
      </Row>


    <GameHistory/>

  </Container>
  )
}

