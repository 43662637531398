import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrain, faCandyCane, faChess, faRobot, faScaleBalanced} from '@fortawesome/free-solid-svg-icons'

export default function BotSettingsModal(props) {

  var fullSize = 12

  return (
    <Modal
    contentClassName="bg-dark text-white"
    show={props.show}
    onHide={props.onHide}>
    <Modal.Header >
      <Modal.Title style={{letterSpacing: 2, fontWeight: 200}}><FontAwesomeIcon icon={faRobot} style={{paddingRight: 8}} /> Bot level</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <Container>
    <Row xs={fullSize} md={fullSize} lg={fullSize}>
    <Button variant="secondary" onClick={() => props.onSelect(2)} style={{marginBottom:16}}>
    <FontAwesomeIcon icon={faCandyCane} style={{paddingRight: 8}} /> Noob
      </Button>
      </Row>
      <Row xs={fullSize} md={fullSize} lg={fullSize}>
     <Button variant="secondary" onClick={() => props.onSelect(3)} style={{marginBottom:16}}>
     <FontAwesomeIcon icon={faScaleBalanced} style={{paddingRight: 8}} /> Medium
        </Button>
        </Row>
        <Row xs={fullSize} md={fullSize} lg={fullSize}>
      <Button variant="secondary" onClick={() => {alert('Magnus Nakamura is very slow'); props.onSelect(4)}} style={{marginBottom:16}}>
      <FontAwesomeIcon icon={faBrain} style={{paddingRight: 8}} /> Magnus Nakamura
        </Button>
        </Row>

          </Container>
           </Modal.Body>
  </Modal>
  );
}
