import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import useUser from '../hooks/useUser';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSecret, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import AccountUtils from '../utils/accountUtils';
import { SERVER_API_URL } from '../config/Constants'
import fetchClient from "../utils/http-common";
import AntiBot from './Antibot';

import {

    View,

} from 'react-native';

export default function AccountModal(props) {
    const [username, setUsername] = useState(props.onLogin ? false : AccountUtils.generateUsername());
    const { user, setUser } = useUser(props.username ? props.username : '');
    const [email, setEmail] = useState(props.email ? props.email : '');
    const [isLoading, setIsLoading] = useState(false);
    const [showBoard, setShowBoard] = useState(false);
    const [password, setPassword] = useState("");
    const [editUsername, setEditUsername] = useState(false);
    const [introText, setIntroText] = useState(props.onLogin ? "Please Login here if you already have an account" : "We created a random username for you. If you wish to change your username, you will need to create an account.")
    const queryParameters = new URLSearchParams(window.location.search)
    const src = queryParameters.get("src")
    
    var usernameColSize = editUsername ? 12 : 8
    var fullSize = 12

    const isEmailValid = () => {
        if (email == "")
            return false
        return !email.includes("sumochess") && email.includes("@") && email.includes(".")
    }

    useEffect(() => {
        if (user) {
            setUsername(user.name)
            console.log("email: " + user.email)
            if (user.email && !user.email.includes("sumochess")) {
                console.log("email: " + user.email)
                setEmail(user.email)
                setIntroText("Edit your username here.")
            }

        }
    }, [user]);

    const renderOneRule = (icon, rule) => {
        var textWidth = icon ? 11 : 12
        return (
            <Row>
                {icon &&
                    <Col xs={1} md={1} lg={1}>
                        <FontAwesomeIcon icon={icon} style={{ paddingRight: 8 }} />
                    </Col>
                }

                <Col xs={textWidth} md={textWidth} lg={textWidth}>
                    <p>{rule}</p>
                </Col>
            </Row>
        )
    }

   const checkUsernameExists = async (username) => {

        try {



            var response = await fetchClient.post(`${SERVER_API_URL}/users/check_username.json`, {username})
            const data = response.data


            if (data && data.code === 550) {
                return true
            }
        }
        catch (e) {
          //  alert('err' + e.message)
            return true
        }

        return false
    }

    const checkEmailExists = async (email) => {

        try {
            var response = await fetchClient.post(`${SERVER_API_URL}/users/check_email.json`, {email})

            const data = response.data
            if (data && data.code === 550) {
                return true
            }
        }
        catch (e) {
           // alert('err' + e.message)
            return true
        }

        return false
    }

    const save = async () => {
        if (isLoading)
            return

        setIsLoading(true)
        var user = {
        }
        if (username)
            user.name = username

        if (props.onPlay && isEmailValid() && !password ) {
            alert('Enter password')
            setIsLoading(false)
            return
        }
        if (isEmailValid()) {
            user.email = email
            user.password = password
        }

        if (username && await checkUsernameExists(username))
        {
            alert('Username already exists')
            setIsLoading(false)
            return
        }

        if (!props.isLoggingIn && email && await checkEmailExists(email))
        {
            alert('Email already exists')
            setIsLoading(false)
            return
        }
        setIsLoading(false)

        if (props.onPlay)
            props.onPlay(user)
        if (props.onSave)
            props.onSave(user)
        if (props.onLogin)
            props.onLogin(user)
    }

    if (showBoard) {
        return (<Modal
            contentClassName="bg-dark text-white"
            show={props.show}
            onHide={props.onHide}>
            <Modal.Header >
                <Modal.Title style={{letterSpacing: 2, fontWeight: 200}}><FontAwesomeIcon icon={faUserSecret} style={{ paddingRight: 8 }} /> {props.onLogin ? "Login to your account" : ""}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                   <AntiBot onSuccess={() => save()}/>

                </Container>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>)
    }
    return (
        <Modal
            contentClassName="bg-dark text-white"
            show={props.show}
            onHide={props.onHide}>
            <Modal.Header >
                <Modal.Title style={{letterSpacing: 2, fontWeight: 200}}><FontAwesomeIcon icon={faUserSecret} style={{ paddingRight: 8 }} /> {props.onLogin ? "Login to your account" : ""}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {renderOneRule(
                        false,
                        introText
                    )}
                    {
                        !props.onLogin &&
                        <Row xs={fullSize} md={fullSize} lg={fullSize}>
                            <Col xs={usernameColSize} md={usernameColSize} lg={usernameColSize}>
                                <Form>
                                    <Form.Group className="mb-3" controlId={isEmailValid() ? "username-form" : "username-form-disabled"}>
                                        <Form.Label>Username </Form.Label>
                                        <Form.Control
                                            type="username"
                                            defaultValue={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            readOnly={!isEmailValid()}
                                            plaintext={!isEmailValid()}
                                            autoFocus
                                            maxLength={50}
                                        />
                                    </Form.Group>
                                </Form>
                            </Col>

                            {!editUsername && isEmailValid() &&
                                <Col xs={2} md={2} lg={2}>
                                    <Button
                                        style={{ marginTop: 30 }}
                                        variant="dark"
                                        onClick={() => setEditUsername(true)}>
                                        <FontAwesomeIcon icon={faPenToSquare} />
                                    </Button>
                                </Col>
                            }
                        </Row>
                    }
                    <Row xs={12} md={12} lg={12}>
                        {(props.onSave || editUsername || props.isLoggingIn) &&
                            <Col xs={fullSize} md={fullSize} lg={fullSize}>
                                <Form>
                                    <Form.Group className="mb-3" controlId="email-form">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder={"Enter Email"}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            autoFocus
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="email-form">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Enter Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            autoFocus
                                        />
                                    </Form.Group>

                                </Form>
                            </Col>}

                    </Row>

                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Close
                </Button>
                <Button variant="warning" onClick={() => props.onPlay && src !== "mobile-app" ? setShowBoard(true) : save()}>
                    {props.onLogin ? "Login" : (isEmailValid() && props.onPlay) ? "Save & Play" : props.onPlay ? "Play" : "Save"}
                </Button>

            </Modal.Footer>
        </Modal>
    );
}
