import axios from "axios";
import { SERVER_API_URL, APP_NAME } from '../config/Constants'


const fetchClient = (contentType = 'application/json') => {
  const defaultOptions = {
    baseURL: SERVER_API_URL,
    headers: {
      'Content-Type': contentType,
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.headers['APISUPPORT'] = 2;
    config.headers['APPNAME'] = APP_NAME

    return config;
  });

  return instance;
};

export default fetchClient();