import { useState } from 'react';

export default function useUser() {
  const getUser = () => {
  
    var user = localStorage.getItem('user') || null;
    if (!user || user === 'undefined' || user === 'null') {
      return null
    }
    user = JSON.parse(localStorage.getItem('user'));

    return user
  };

  const [user, setUser] = useState(getUser());

  const saveUser = user => {
    localStorage.setItem('user', JSON.stringify(user));


    setUser(user);
  };
  return {
    setUser: saveUser,
    user
  }
}