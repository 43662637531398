import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHand, faPoop, faCamera} from '@fortawesome/free-solid-svg-icons'

export default function PauseModal(props) {

    const renderOneRule = (icon, rule) => {
        var textWidth = icon ? 11 : 12
        return (
            <Row>
                {icon &&
                <Col xs={1} md={1} lg={1}>
                    <FontAwesomeIcon icon={icon} style={{paddingRight: 8}} />
                </Col>
                }

                <Col xs={textWidth} md={textWidth} lg={textWidth}>
                    <p>{rule}</p>
                </Col>
            </Row>
        )
    }

  return (
    <Modal
    contentClassName="bg-dark text-white"
    show={props.show}
    onHide={props.onHide}>
    <Modal.Header >
      <Modal.Title style={{letterSpacing: 2, fontWeight: 200}}><FontAwesomeIcon icon={faHand} style={{paddingRight: 8}} />  Pause Game</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <Container>
    {renderOneRule(
        faPoop,
        "To prevent cheating, pausing game is only allowed for going to the toilet, and only for number 2."
    )}

            {renderOneRule(
        faCamera,
        "You will need to upload a picture of your stool later for verification purposes."
    )}
            {renderOneRule(
        false,
        "Are you sure you want to pause the game?"
    )}

          </Container>
           </Modal.Body>
    <Modal.Footer>
    <Button variant="secondary" onClick={props.onHide}>
        Close
      </Button>
      <Button variant="warning" onClick={props.onPause}>
        Pause Game
      </Button>

    </Modal.Footer>
  </Modal>
  );
}
