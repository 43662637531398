import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChess, faTriangleExclamation, faHandPointRight, faCircleExclamation, faLink } from '@fortawesome/free-solid-svg-icons'

export default function HelpModal(props) {

    const renderOneRule = (icon, rule) => {
        return (
            <Row>
                <Col xs={1} md={1} lg={1}>
                    <FontAwesomeIcon icon={icon} style={{ paddingRight: 8 }} />
                </Col>
                <Col xs={11} md={11} lg={11}>
                    <p>{rule}</p>
                </Col>
            </Row>
        )
    }

    const renderCredits = () => {
        return (
            <Row style={{
                backgroundColor: "rgba(255,255,255,0.1)",
                padding: 12,
                paddingBottom: 0,
                paddingLeft: 0,
                marginBottom: 16,
                borderRadius: 8
            }}>

                        <Col xs={1} md={1} lg={1}>
                            <FontAwesomeIcon icon={faCircleExclamation} style={{ paddingRight: 8 }} />
                        </Col>
                        <Col xs={11} md={11} lg={11}>
                            <p> this is a website about the chess variant Sumochess, if you are looking for the Sumo Chess Game by <b>Thaeux</b>, you can find it here: <a id="credit-link" href="https://thaeux.itch.io/sumo-chess" target="_blank">thaeux.itch.io/sumo-chess</a></p>
                        </Col>
                    </Row>
        )
    }


    return (
        <Modal
            contentClassName="bg-dark text-white"
            show={props.show}
            onHide={props.onHide}>
            <Modal.Header closeButton closeVariant="white">
                <Modal.Title style={{ letterSpacing: 2, fontWeight: 200 }}><FontAwesomeIcon icon={faChess} style={{ paddingRight: 8 }} />  Welcome to SumoChess!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {renderOneRule(
                        faHandPointRight,
                        "Pieces are not taken, but pushed off the board, except for the king, which can take."
                    )}
                    {renderOneRule(
                        faHandPointRight,
                        "You can only push one piece at a time, and cannot push your own pieces."
                    )}
                    {renderOneRule(
                        faHandPointRight,
                        "Knights push horizontally."
                    )}
                    {renderOneRule(
                        faHandPointRight,
                        "You cannot push back immediately to the position you were pushed from."
                    )}
                    {renderOneRule(
                        faHandPointRight,
                        "Game ends when king is in checkmate."
                    )}
                    {renderOneRule(
                        faHandPointRight,
                        "Normal rules apply."
                    )}
                    {renderOneRule(
                        faTriangleExclamation,
                        "En passant is a grotesque rule. It is forbidden. Offenders will be canned."
                    )}
                    {
                        props.showCredits && renderCredits()
                    }
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
