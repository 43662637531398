import React, { useState, useEffect } from 'react';
import Utils from '../utils/utils';

const CountdownTimer = ({ timestamp, onExpire, getRealTime }) => {
  const [timeLeft, setTimeLeft] = useState(Math.max(timestamp - getRealTime(), 0));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(Math.max(timestamp - getRealTime(), 0));
      //console.log('timeLeft', timeLeft, timestamp, Date.now());
    }, 1000);

    if (Math.floor(timeLeft / 1000) === 0 && onExpire)
      onExpire();

    return () => clearInterval(intervalId);
  }, [timestamp]);

  const secondsLeft = Math.floor(timeLeft / 1000);
  
  const txt = Utils.convertSecondsToTimerMinutes(secondsLeft)
  return (
    <div>
      {txt}
    </div>
  );
}

export default CountdownTimer;