import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChess, faTriangleExclamation, faHandPointRight } from '@fortawesome/free-solid-svg-icons'
import Countdown from './Countdown';

export default function SimpleAlert(props) {
    const alertContent = props.alertContent
    const textColor = alertContent.textColor ? alertContent.textColor : "text-white"

    const renderOneRule = (icon, rule) => {
        return (
            <Row>
                <Col xs={1} md={1} lg={1}>
                    <FontAwesomeIcon icon={icon} style={{ paddingRight: 8 }} />
                </Col>
                <Col xs={11} md={11} lg={11}>
                    <p>{rule}</p>
                </Col>
            </Row>
        )
    }
    return (
        <Modal
            contentClassName={`bg-dark ${textColor}`}
            show={props.show}
            onHide={props.onHide}>
            {
                alertContent.title &&
                <Modal.Header >
                    <Modal.Title style={{letterSpacing: 2, fontWeight: 200}}><FontAwesomeIcon icon={faChess} style={{ paddingRight: 8 }} />  {alertContent.title}</Modal.Title>
                </Modal.Header>
            }

            <Modal.Body>
                <Container>
                    <Row>
                        {alertContent.icon &&
                        <Col xs={1} md={1} lg={1}>
                            <FontAwesomeIcon icon={alertContent.icon} style={{ paddingRight: 8 }} />
                        </Col>
                        }

                        <Col xs={11} md={11} lg={11}>
                            <p>{alertContent.text}</p>
                        </Col>
                    </Row>

                </Container>
            </Modal.Body>
            <Modal.Footer>
                {!alertContent.noClose && <Button variant="secondary" onClick={props.onHide}>
                    Close
                </Button>}
                { alertContent.buttonAction &&
                     <Button variant="warning" onClick={alertContent.buttonAction}>
                     <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {alertContent.buttonTimeOut && <Countdown time={alertContent.buttonTimeOut} />}
                         {alertContent.buttonText}
                        
                     </span>
                 </Button>
                }
            </Modal.Footer>
        </Modal>
    );
}
