import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logo.svg';
import { HEADER_HEIGHT } from './config/Constants'
//import './App.css';
import './App.scss';
import Main from './containers/Main';
import Login from './containers/Login';
import Board from './containers/Board';
import Profile from './containers/Profile';

import Game from './containers/Game';


import Image from "./components/Image"
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import useToken from './hooks/useToken';
import useUser from './hooks/useUser';
import { useWindowSize } from './hooks/useWindowSize'
import strings from "./config/strings";
import Utils from './utils/utils'
import { SECONDARY_COLOR } from "./config/styles";
import { confirmAlert } from 'react-confirm-alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faInfoCircle, faLightbulb, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import AccountModal from './components/AccountModal';
import { loginUser } from './containers/Login'
import SimpleAlert from './components/SimpleAlert'
import HelpModal from './components/HelpModal'
//import explosion from './imgs/explosion.mp4';

import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
} from 'react-native';

var __DEV__ = true;
function RequireAuth({ children }) {

  /*let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }*/

  return children;
}


function App() {
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  //const { isLoaded, user } = useUser();
  const { size } = useWindowSize();
  const [showFire, setShowFire] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [alertContent, setAlertContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search)
  const src = queryParameters.get("src")
  const [srcState, setSrcState] = useState("")
  console.log("SRC Param", src)

  useEffect(() => {
    window.addEventListener('fire', (event) => {
      console.log('fire event', event)
      setShowFire(true)
      console.log('fire1', showFire)
      setTimeout(() => {

        setShowFire(false)
        console.log('fire2', showFire)
      }, 3000)
    });
    window.addEventListener("login", (event) => {
      console.log('login event',
        event.detail.user
      )
      setUser(event.detail.user)
    }
    );
    if (src) {
      setSrcState(`?src=${src}`)
    }
  }, []);

  if (user)
    var { badgeImg, badgeText } = Utils.getLevelInfo(user.level)

  const login = async (user) => {
    setLoading(true)
    if (!token) {
      var data = await loginUser(user)
      console.log('Login data', data)

      if (!data || !data.auth_token) {
        alert('An error occured')
        return
      }
      setToken(data.auth_token);
      setUser(data.user)

    }
    else {

      alert(JSON.stringify(data))
    }
  }


  const renderInner = () => {
    return (
      <Routes>

        <Route path="/Profile" element={<Profile />}>


        </Route>
        <Route path="/Board" element={<Board />}></Route>
        <Route exact path="/" element={<Main />}>
        </Route>
        <Route path="/Game/:id" element={<Game />}>
        </Route>



      </Routes>

    )
  }
  const renderNavBar = () => {
    return (
      <Navbar bg="dark" data-bs-theme="dark" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/">SumoChess</Navbar.Brand>
          <Nav className="ms-auto">
            <Nav.Link href="/">Lobby</Nav.Link>
            {user ? <Nav.Link href="/Profile">Profile</Nav.Link> :
              <Nav.Item
              >
                <Nav.Link
                  onClick={() =>
                    setShowAccount(true)
                  } >Login</Nav.Link>
              </Nav.Item>

            }
              {
                user ?
                  <Nav.Item style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: 12,
                    marginLeft: 12,
                  }}>
                    <TouchableOpacity
                      onPress={() => {
                        setAlertContent({
                          variant: 'danger',
                          text: 'Are you sure you want to log out?',
                          textColor: "text-white",
                          icon: faRightFromBracket,
                          buttonText: "Yes",
                          buttonAction: () => {
                            localStorage.clear();
                            window.location.reload();
                          }
                        })

                      }}>
                      <FontAwesomeIcon icon={faRightFromBracket} color={"rgba(255,255,255,0.6)"}  />
                    </TouchableOpacity>
                  </Nav.Item> :
                  <Nav.Item style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: 6,
                    marginLeft: 6,

                  }}><Text style={{
                    color: "#fff",
                    fontSize: 12,
                    letterSpacing: 1,
                    textAlign: "center",
                    marginHorizontal: 12,

                  }}> {'Not connected'}</Text>
                  </Nav.Item>
              }
              <Nav.Item style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 12,
                marginRight: 12,
              }}>
                <TouchableOpacity
                  onPress={() => {
                    setShowHelp(true)
                  }}>
                  <FontAwesomeIcon icon={faLightbulb} color={"rgba(255,255,255,0.6)"}  />
                </TouchableOpacity>
              </Nav.Item>
          </Nav>
          </Container >
      </Navbar >
    )
  }

  const withHeader = src != "mobile-app"
  const bgHeight = withHeader ? Dimensions.get('window').height - HEADER_HEIGHT : Dimensions.get('window').height
  return (
    <div className="wrapper" style={{ zIndex: 30 }}>
      <BrowserRouter>
        {withHeader && renderNavBar()}
        {
          showFire &&
          <div class="video-container">
            <video id="video-bg" className='videoTag' autoPlay loop muted>
              <source src={`/imgs/explosion2.mp4`} type='video/mp4' />
            </video>
          </div>

        }

        <Container fluid className="pt-5 pb-3 pl-3 pr-3" style={{
          height: bgHeight,
          backgroundImage: showFire ? undefined : `url(/imgs/lava3.jpeg)`,
          backgroundSize: 'cover',

        }}>
          {alertContent &&
            <SimpleAlert
              show={alertContent}
              onHide={() => setAlertContent(false)}
              alertContent={alertContent}
            />
          }
          {
            showHelp &&
            <HelpModal show={showHelp} onHide={() => setShowHelp(false)} showCredits={true} />
          }
          <AccountModal
            show={showAccount}
            isLoggingIn={true}
            onHide={() => setShowAccount(false)}
            onLogin={(user) => {

              //console.log('Start Game')
              setShowAccount(false)
              login(user)
            }
            } />

          <Row className="justify-content-center">
            <Col xs="12" md="10" lg="9" xl="6" className="text-white justify">
              {renderInner()}
            </Col>

          </Row>

        </Container>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
          integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN"
          crossorigin="anonymous"
        />
      </BrowserRouter>

    </div>
  );
}

export default App;
