
import React, { Component } from "react";
import strings from "../config/strings";
import Image from "../components/Image"
import Utils from "../utils/utils";
import {SECONDARY_COLOR} from "../config/styles";
import { useState, useEffect, useMemo, useRef } from 'react';
import GameService from "../services/GameService";
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faHourglassHalf, faChess , faArrowRight} from '@fortawesome/free-solid-svg-icons'
import useUser from '../hooks/useUser';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

const { width, height } = Dimensions.get("window");

export default function GameHistory() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
  const [games, setGames] = useState([])
  const { user, setUser } = useUser();

  const getGames = async () => {


    setLoading(true)
    try {
    var res = await GameService.getGamesHistory()
    }
    catch (e) {
      alert(e.message)
      console.error(e)
      return
    }
    //alert(JSON.stringify(res.data))
    setLoading(false)

    if (res.status == 200) {
      var games = res.data
      if (games.length > 0) {
        setGames(games)
      }
    }
  }




  useEffect(() => {

    if (user)
    getGames()
  }, []);

  const testGames = [
    {
      id: 1,
      created_at: new Date(),
      time: 3600,
      player1_id: 1,
      player2_id: 2
    },
    {
      id: 2,
      created_at: new Date(),
      time: 1800,
      player1_id: 1,
      player2_id: 3
    },
    {
      id: 3,
      created_at: new Date(),
      time: 1800,
      player1_id: 1,
      player2_id: 3
    }
  ]

  const renderItem = ({item} ) => {
    return (

    <ListGroup.Item
    className="d-flex justify-content-between align-items-center"
    action
    data-bs-theme="dark"
    variant="dark"
    style={{
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      opacity: 0.8,
    }}>

      <View style={{
        textWrap: "wrap",
        width: "90%"
      }}>

      <Text style={{
        color: "#FFE492",
        //color: "black",
        fontWeight: 600,
        fontSize: 10,
        //opacity: 0.6
        }}>
        <FontAwesomeIcon style={{marginRight: 6, fontSize: 10}} icon={faStar} />{moment(item.created_at).fromNow()}     <FontAwesomeIcon style={{marginRight: 6, fontSize: 10}} icon={faHourglassHalf} /> {!item.player1_id || !item.player2_id ? 'Bot Game' : Utils.getHumanTime(item.time)}</Text>
      </View>
      <TouchableOpacity

        onPress={() => {
          navigate('/game/' + item.id)
        }}
      >
        <FontAwesomeIcon icon={faArrowRight} style={{color: "#FFD145", fontSize: 18}}/>
        </TouchableOpacity>
    </ListGroup.Item>

  )
  }

  if (loading)
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );

    return (

<ListGroup
            style={{backgroundColor: "rgba(0,0,0,0.15)", marginTop: 32, borderRadius: 8,marginBottom: games.length > 0 ? 24 : 0}}
            variant="flush" defaultActiveKey="#link2">
              <Text style={{color:"#fff", textAlign:'center', margin:16}}>History </Text>
              <ScrollView
       //style={{maxHeight: "20%"}}
       contentContainerStyle={{
         height: isMobile ? Dimensions.get('window').height * 0.25 : Dimensions.get('window').height * 0.35,
         justifyContent: 'space-between',
         borderRadius: 12,
       }}
       >
              {
                games.map((item) => {
                  return renderItem({item})
                })
              }
                     </ScrollView>
            </ListGroup>




    );


}

const styles = StyleSheet.create({

  titleText: {
    color: "#555",
    fontSize: 15,
    marginRight: 6,
    marginLeft: 6,
  },

});
