import Button from 'react-bootstrap/Button';
import { useState, useEffect, useRef } from 'react';
import useUser from '../hooks/useUser';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSecret, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import AccountUtils from '../utils/accountUtils';
import { SERVER_API_URL } from '../config/Constants'
import fetchClient from "../utils/http-common";
import ListGroup from 'react-bootstrap/ListGroup';
import GameUtils from '../utils/gameUtils'
import moment from 'moment';

import {
    Text,
    View,
    TextInput,
    ScrollView,

} from 'react-native';



export default function Chat(props) {
    var fullSize = 12
    const scrollViewRef = useRef();

    const [message, setMessage] = useState('')
    const [messageList, setMessageList] = useState(props.messages)

    moment.updateLocale('en', {
        relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: 'few secs',
            ss: '%d secs',
            m: "a min",
            mm: "%d mins",
            h: "an h",
            hh: "%d hrs",
            d: "a d",
            dd: "%d ds",
            w: "a w",
            ww: "%d ws",
            M: "a m",
            MM: "%d ms",
            y: "a yr",
            yy: "%d yrs"
        }
    });

    useEffect(() => {
        setMessageList(props.messages)
    }, [props.messages])

    const postChat = async () => {

        await GameUtils.postChat(props.gameId, message)

        setMessageList([...messageList, { message: message, user_id: props.my_user_id, created_at: new Date() }])
        setMessage('')
    }

    const renderItem = (item) => {

        return (
            <ListGroup.Item
                className="d-flex mb-3 justify-content-between align-items-center bg-transparent"
                style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',


                }}>
                <View style={{
                    textWrap: "wrap",
                    width: "18%",

                    alignSelf: 'flex-start',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                }}> <Text style={{ color: "rgba(255,255,255,0.5)", fontWeight: 300, fontSize: 12 }}>
                        {moment(item.created_at).fromNow()}:</Text></View>


                <View style={{
                    textWrap: "wrap",
                    width: "82%",
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    marginLeft: 4,
                }}>
                    <Text style={{
                        color: item.user_id === props.my_user_id ? "#fff" : "#FFE492",
                        fontWeight: 500,
                        fontSize: 12,
                        letterSpacing: 1,
                    }}>{item.message}</Text>

                </View>

            </ListGroup.Item>
        )
    }

    return (
        <Modal
            //size="lg"
            contentClassName="bg-dark text-white"
            show={props.show}
            onHide={props.onHide}>
            <Modal.Header >
                <Modal.Title style={{ letterSpacing: 2, fontWeight: 200 }}><FontAwesomeIcon icon={faUserSecret} style={{ paddingRight: 8 }} />Messages</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>

                    <Row xs={12} md={12} lg={12}>

                        <Col xs={fullSize} md={fullSize} lg={fullSize}>

                                <ScrollView
                                ref={scrollViewRef}
                                onContentSizeChange={() => scrollViewRef.current.scrollToEnd({ animated: true })}
                                contentContainerStyle={{maxHeight: 450}}
                                className="mb-3"
                                controlId="messages">
                                    {messageList && messageList.map((message, index) => {

                                        return renderItem(message)
                                    })}
                                </ScrollView>
                                <Form>
                                <Form.Group className="mb-3" controlId="new_message">

                                    {/* <Form.Control
                                            id="new_message"
                                            type="text"
                                            placeholder="Enter message"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            autoFocus
                                        /> */}
                                    <TextInput
                                        id="new_message"
                                        style={{
                                            backgroundColor: "rgba(43, 165, 247, 0.4)",
                                            marginTop: 12,
                                            width: "100%",
                                            color: "#fff",
                                            borderTopLeftRadius: 40,
                                            borderTopRightRadius: 40,
                                            borderBottomLeftRadius: 40,
                                            paddingVertical: 12,
                                            paddingHorizontal: 16,
                                            //borderColor: "rgba(43, 165, 247, 0.8)",
                                            borderWidth: 0
                                        }}
                                        placeholder='Enter message'
                                        onChangeText={text => setMessage(text)}
                                        multiline
                                        value={message}
                                        autoFocus
                                    />

                                </Form.Group>
                                {
                                   (!messageList || messageList.length === 0)  &&
                                    <Form.Text id="passwordHelpBlock" style={{ color: "#fff" }} className="pt-2" >
                                        Start chatting with your opponent
                                    </Form.Text>
                                }


                            </Form>
                        </Col>

                    </Row>

                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Close
                </Button>
                <Button variant="warning" onClick={() => postChat()}>
                    Send
                </Button>

            </Modal.Footer>
        </Modal>
    );
}
