import { STUPID_ADJ, JAP_FIRST_NAMES, JAP_LAST_NAMES } from '../config/Constants'

export default class AccountUtils {

    static generateUsername() {
      //pic a random STUPID_ADJ
      var adj = STUPID_ADJ[Math.floor(Math.random() * STUPID_ADJ.length)]
      var firstN = JAP_FIRST_NAMES[Math.floor(Math.random() * JAP_FIRST_NAMES.length)]
      var lastN = JAP_LAST_NAMES[Math.floor(Math.random() * JAP_LAST_NAMES.length)]

      return `${firstN}_${adj}${Math.floor(Math.random() * 2) ? 'chan' : 'san'}${Math.floor(Math.random() * 10000)}`


    }
}
