import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faUserSecret } from '@fortawesome/free-solid-svg-icons'
import Utils from '../utils/utils'
import Countdown from './Countdown';

import {
  View,
  Text,
} from 'react-native';


export default function GameAvailable(props) {
  const [show, setShow] = useState(props.show);
  const [gameToJoin, setGameToJoin] = useState(props.gameToJoin);

  const handleClose = () => props.setShow(false);
  const handleShow = () => setShow(true);

  const renderOneInfo = (name, text) => {
    return (
      <View style={{ marginVertical: 4, marginLeft: 6 }}>
        {name === 'time' && <Text style={{ letterSpacing: 2, color: "rgba(255,255,255,1)" }} >{Utils.capitalizeFirstLetter(name)}:<Text style={{ color: "rgba(255,255,255,0.6)" }} > {text === -1 ? "10 min" : text}</Text></Text>}
        {name !== 'time' && <Text style={{ letterSpacing: 2, color: "rgba(255,255,255,1)" }} >{Utils.capitalizeFirstLetter(name)}:<Text style={{ color: "rgba(255,255,255,0.6)" }} > {text}</Text></Text>}
      </View>

    )
  }
  return (
    <Offcanvas show={props.show} onHide={handleClose} placement="top" className="bg-dark" >
      <Offcanvas.Header className="bg-dark">
        <Offcanvas.Title style={{ color: "#FFD145", letterSpacing: 1, opacity: 0.9 }}><FontAwesomeIcon icon={faUserSecret} /> New Game Available with a real human</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body >
        <View style={{
          marginTop: -16
        }}>
        {
          ["name"].map((info) => {
            return renderOneInfo(info, gameToJoin[info])
          }
          )
        }
        </View>
        <View style={{
          flexDirection: "row"

        }}>
        {
          ["elo", "time"].map((info) => {
            return renderOneInfo(info, gameToJoin[info])
          }
          )
        }
        </View>

        
        <View style={{ marginTop: 16, marginLeft: 6, marginBottom: 14, flexDirection: "row" }}>
          <Button variant="dark" onClick={handleClose} size="sm" >
            Ignore
          </Button>
          <Button variant="warning" onClick={props.acceptGame} className="me-5" size="sm">
            Join Game
          </Button>

          
        </View>
        <Text style={{ letterSpacing: 2, color: "rgba(255,255,255,0.6)", fontSize: 12, fontStyle: "italic" }} >You can go back to your bot game at any time by going to your profile</Text>

      </Offcanvas.Body>


    </Offcanvas>
  );
}
