import { SERVER_API_URL } from '../config/Constants'
import strings from "../config/strings";
import fetchClient from "../utils/http-common";

export default class Utils {

	static getTime() {
		return Date.now()
	}

	static getUID() {
		return Math.random().toString(36).slice(2)
	}

  static cutString(name, length) {
    if (name.length > length) {
      return (name.substring(0, length) + "..." )
    }
    return name
  }

	static hasValidSubscription() {
		return true
	}

  static replaceAll(out, search, replacement) {
    return out.split(search).join(replacement)
  }

	static copyObject(obj) {
		return JSON.parse(JSON.stringify(obj))
	}

  static capitalizeFirstLetter(string) {
    if (!string || string.length == 0)
      return string;

      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

	static getAttachementUrl(attachmentId, size = 'normal') {
    return `${SERVER_API_URL}/pattern_attachments/${attachmentId}/get_url?size=${size}`
  }

  static getArrayAsString(array, separator) {
    var str = ''


    for (var i = 0; i < array.length; i++) {
      if (i != 0)
        str += separator

      str += array[i]
    }


    return str
  }

	static convertSecondsToTimer(seconds) {


      var timeElapsed = seconds

      var hoursElapsed = Math.floor(timeElapsed / 60 / 60)
      var minutesElapsed = parseInt((timeElapsed - (hoursElapsed * 60 * 60)) / 60)


      var timerStr = this.format0(hoursElapsed) + ":" + this.format0(minutesElapsed) + ":" + this.format0(timeElapsed % 60)


      return (timerStr)

    }

    static format0(nb) {

        if (nb.toString().length == 1)
          return "0" + nb
        return nb.toString()
    }

    static isLandscape() {
    	return true
    }

    static isTablet() {
    	return true
    }


    static getLevelInfo(level) {
		//if(__DEV__) console.log('GET LEVEL', isCrochet)
return { badgeText: 'Sumochess'}
    if (!level)
      level = 1
    var levelImg = level
    if (levelImg > 6)
      levelImg = 6

		return {badgeImg:`/imgs/badges/${levelImg}.png`, badgeText: strings[`badge${level}`]}

	}

  static getRedirectUrl(url, callback) {

     fetchClient.get(url).then(response => {
        console.log("Get video url data", response)
         callback(response.url)
       }
      ).catch(error => alert(error.message))
 }

 static makeId(length) {
   var result = '';
   var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

static convertSecondsToTimerMinutes(seconds) {

  var timeElapsed = seconds

  var hoursElapsed = Math.floor(timeElapsed / 60 / 60)
  var minutesElapsed = parseInt((timeElapsed - (hoursElapsed * 60 * 60)) / 60)
  var secondsElapsed = parseInt((timeElapsed - (hoursElapsed * 60 * 60) - (minutesElapsed * 60)))


  if (hoursElapsed > 0)
    return this.format0(hoursElapsed) + ":" + this.format0(minutesElapsed) + ':' + this.format0(secondsElapsed)
  return this.format0(minutesElapsed) + ':' + this.format0(secondsElapsed)

}

static format0(nb) {

  if (nb.toString().length == 1)
    return "0" + nb
  return nb.toString()
}

  // static getRedirectUrl(url, callback) {
  //   var user = new User();


  //   let options = Object.assign({ method: "get" }, null);
  //   options.headers = Utils.getJSONHeaders(user.get().authToken);


  //     var fetchResult = fetch(url, options).then(data => {
  //       callback(data.url)

  //     }
  //     ).catch(error => alert(error.message))
  // }

  static getHumanTime(time) {

    time = parseInt(time)


    switch (time) {
      case -1:
        return '10min'
      case 60:
        return '1min'
      case 180:
        return '3min'
      case 600:
        return '10min'
      case 1800:
        return '30min'
      case 3600:
        return '1h'
      case 604800:
        return '1 week'
      default:
        return '10min'
    }
  }
	static getJSONHeadersWithoutAuth() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      dataType: "json",
      "APISUPPORT": 2,
      "APPNAME": "web"
    };
  }

  static getJSONHeaders(authToken, isFormData = false) {
    return {
      Accept: "application/json",
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      dataType: "json",
      Authorization: "Bearer " + authToken,
      "APISUPPORT": 2,
      "APPNAME": "web"
    };
  }
}
